/**
 * 获取当前日期的前一天23点59分59秒
 */
export function getLastDayEnd(value: Date): Date {
  value.setHours(0);
  value.setMinutes(0);
  value.setSeconds(0);
  return new Date(value.getTime() - 1000);
}

/**
 * 获取当前日期的后一天的0点0分0秒
 */
export function getNextDayStart(value: Date): Date {
  value.setHours(23);
  value.setMinutes(59);
  value.setSeconds(59);
  return new Date(value.getTime() + 1000);
}
