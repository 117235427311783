import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'stColFilter'})
export class StColFilterPipe implements PipeTransform {
  transform(items: any[]): any {
    if (!items){
      return items;
    }
    return items.filter(e => e.checked);
  }
}

