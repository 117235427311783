import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponseBase,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ShowyePageAuthService } from '@showye/showye-page-base';
import { format } from 'date-fns';

const CODEMESSAGE: { [key: number]: string } = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private auth: ShowyePageAuthService) {}

  private get notification(): NzNotificationService {
    return this.injector.get(NzNotificationService);
  }

  private get http(): _HttpClient {
    return this.injector.get(_HttpClient);
  }

  private goTo(url: string): void {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase): void {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    const errortext = CODEMESSAGE[ev.status] || ev.statusText;
    this.notification.error(`请求错误 ${ev.status}: ${ev.url}`, errortext);
  }

  private handleError(ev: HttpErrorResponse): Observable<any> {
    // console.log('ev err: ', ev);
    // if (ev.status > 0) {
    //   this.injector.get(_HttpClient).end();
    // }
    switch (ev.status) {
      case 401:
        this.notification.error(ev.error && ev.error.message ? ev.error.message : `未登录或登录已过期，请重新登录。`, '');
        // this.notification.error(ev.error && ev.error.message ? ev.error.message : `未登录或登录已过期，请重新登录。`, ev.statusText + '@' + ev.message);
        // 清空 token 信息
        this.auth.logout();
        break;
      case 400:
        // console.log(400, ev.error && ev.error.message, ev.error.message);
        // ev.error.data
        this.notification.error(ev.error && ev.error.message ? ev.error.message : `参数提交错误`, '');
        // this.notification.error(ev.error && ev.error.message ? ev.error.message : `参数提交错误`, ev.error.data ? JSON.stringify(ev.error.data) : ev.statusText + '@' + ev.message);
        break;
      case 403:
        // ev.statusText + '@' + ev.message
        this.notification.error(ev.error && ev.error.message ? ev.error.message : `权限不足。`, '');
        //   this.goTo(`/exception/${ev.status}`);
        break;
      case 404:
        // '' + ev.status
        this.notification.error('接口不存在', '');
        break;
      case 500:
        // ev.statusText + '@' + ev.message
        this.notification.error(ev.error && ev.error.message ? ev.error.message : `服务器出现了问题，请联系管理员。`, '');
        // this.goTo(`/exception/${ev.status}`);
        break;
      default:
        // console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
        // ev.error.submessage ? ev.error.submessage : ev.error.data
        this.notification.error(ev.error && ev.error.message ? ev.error.message : `网络连接异常。`, '');
        break;
    }
    return throwError(ev);
  }

  private handleData(ev: HttpResponseBase): Observable<any> {
    // console.log('ev data: ')
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    // if (ev.status > 0) {
    //   this.injector.get(_HttpClient).end();
    // }
    const tk = ev.headers.get('Authorization');
    if (tk && tk.split('.').length === 3) {
      this.auth.setToken(tk);
    }
    return of(ev);
  }

  private getAdditionalHeaders(headers?: HttpHeaders): { [name: string]: string } {
    const res: { [name: string]: string } = {};
    if (!headers?.has('Accept-Language')) {
      res['Accept-Language'] = 'zh-CN';
    }
    if (this.auth.token) {
      res.Authorization = `Bearer ${this.auth.token}`;
    }
    return res;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url;
    // if (!url.startsWith('https://') && !url.startsWith('http://')) {
    //   url = environment.host + environment.path + url;
    // }
    const newReq = req.clone({ url, setHeaders: this.getAdditionalHeaders(req.headers) });
    // this.shiftDate(newReq.body);
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // console.log('ev: ', event)
        // 允许统一对请求错误处理
        if (event instanceof HttpResponseBase) {
          return this.handleData(event);
        }
        // 若一切都正常，则后续操作
        // console.log('ev ok: ')
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleError(err)),
    );
  }

  shiftDate(body: any): void {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = format(new Date(body[key]), "yyyy-MM-dd'T'HH:mm:ss") + 'Z';
      } else if (typeof value === 'object') {
        this.shiftDate(value);
      }
    }
  }
}
