import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutDefaultModule } from '@delon/theme/layout-default';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ShowyePageBaseModule } from '@showye/showye-page-base';
import { ExceptionModule } from '@delon/abc/exception';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { LayoutComponent } from './layout.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzFormModule } from 'ng-zorro-antd/form';
import { SharedModule } from '@shared';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { PageTagComponent } from './page-tag.component';
import { NzTagModule } from 'ng-zorro-antd/tag';

const COMPONENTS = [LayoutComponent, PageTagComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LayoutDefaultModule,
    NzDropDownModule,
    NzInputModule,
    NzAvatarModule,
    NzIconModule,
    NzBadgeModule,
    ShowyePageBaseModule,
    ExceptionModule,
    NzPopconfirmModule,
    SharedModule,
    NzSelectModule,
    NzFormModule,
    NzTagModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class LayoutModule {}
