<layout-default [options]="options" [content]="contentTpl">
  <ng-container *ngIf="menus && menus.length > 0">
    <layout-default-header-item direction="left" *ngFor="let item of menus">
      <a layout-default-header-item-trigger *ngIf="isExternal(item.link); else menuElse" [href]="item.link" target="_blank">
        <i *ngIf="!!item.icon" nz-icon [nzType]="item.icon"> </i> {{ item.text }}
      </a>
      <ng-template #menuElse>
        <a class="" layout-default-header-item-trigger [routerLink]="item.link" (click)="onMenuChange(item.link)">
          <i *ngIf="!!item.icon" nz-icon [nzType]="item.icon"> </i> {{ item.text }}
        </a>
      </ng-template>
    </layout-default-header-item>
  </ng-container>

  <!--  <ng-container *ngIf="menus && menus.length > 0">-->
  <!--    <div class='alain-default__aside ng-star-inserted'>-->
  <!--      <div class='alain-default__aside-inner'>-->
  <!--        <layout-default-nav></layout-default-nav>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </ng-container>-->

  <layout-default-header-item direction="right">
    <div style="display: flex">
      <div *ngIf="buttonGroup?.cockpit" class="alain-default__nav-item d-flex align-items-center px-sm" [routerLink]="['/overview']">
        <img style="margin-right: 8px" src="../../assets/screen.svg" />
        驾驶舱
      </div>

      <!--      <div style="margin: 0 5px 0 15px" class="alain-default__nav-item d-flex align-items-center px-sm">-->
      <!--        &lt;!&ndash;        <div class="nb-message-wrapper" (click)="dumpMyMessage(1)">&ndash;&gt;-->
      <!--        <img style="margin-right: 8px" src="../../assets/info.svg" />-->
      <!--        &lt;!&ndash;          <div *ngIf="totalCount > 0" class="number">&ndash;&gt;-->
      <!--        &lt;!&ndash;            {{ getCount(totalCount) }}&ndash;&gt;-->
      <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      </div>-->

      <div
        class="alain-default__nav-item d-flex align-items-center px-sm"
        nz-dropdown
        nzPlacement="bottomRight"
        [nzDropdownMenu]="userMenu"
      >
        <nz-avatar [nzSrc]="staffAvatar" nzSize="small" class="mr-sm"></nz-avatar>
        {{ user.name }}
      </div>
    </div>

    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <div nz-menu class="width-sm">
        <!--        <div nz-menu-item [routerLink]="reset"><i nz-icon nzType="user" class="mr-sm"></i>个人中心</div>-->
        <!--        <li nz-menu-divider></li>-->
        <div nz-menu-item (click)="logout()"><i nz-icon nzType="logout" class="mr-sm"></i>退出登录</div>
      </div>
    </nz-dropdown-menu>
  </layout-default-header-item>

  <ng-template #contentTpl>
    <div style="position: relative; top: 10px" class="section-content-frame">
      <!--<page-header title=""></page-header>-->
      <!--<app-page-tag></app-page-tag>-->
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</layout-default>
