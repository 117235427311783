import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ShowyePageApiService,
  ShowyePageAuthService,
  ShowyePageMenuService,
  ShowyeMessageSummary,
  ShowyePageMessageService,
} from '@showye/showye-page-base';
import { MenuService, SettingsService } from '@delon/theme';
import { LayoutDefaultOptions } from '@delon/theme/layout-default';
import { RouterCacheService } from '../services/router-cache.service';
import { NbApiService } from '@service/nb-api.service';
import { NbAvatarService } from '@service/nb-avatar.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  styleUrls: ['layout.scss'],
  templateUrl: 'layout.component.html',
})
export class LayoutComponent {
  @Input() url: any;
  options: LayoutDefaultOptions = {
    logoExpanded: `./assets/logo-full.png`,
    logoCollapsed: `./assets/logo.png`,
  };
  totalCount = 0;
  totalMsgs: any[] = [];
  menus: any[] = [];
  menusUsed: any[] = [];

  get user(): any {
    return this.auth.user;
  }

  get reset(): string {
    return '/my/profile';
  }

  isExternal(link: string): boolean {
    return /^(https?:)?\/\//g.test(link!);
  }

  logout(): void {
    if (this.url) {
      window.location.href = this.url;
    }
    this.auth.logout();
  }

  buttonGroup: any;
  userId: any;
  downloadUrl = this.api.getDownloadUrl();
  staffAvatar = '/assets/avatar.svg';

  constructor(
    private settings: SettingsService,
    private menuService: ShowyePageMenuService,
    private router: Router,
    private messageService: ShowyePageMessageService,
    private auth: ShowyePageAuthService,
    public api: ShowyePageApiService,
    private nbApi: NbApiService,
    private route: ActivatedRoute,
    protected menusService: MenuService,
    private routerCacheService: RouterCacheService,
    private nbAvatarService: NbAvatarService,
  ) {
    this.userId = this.auth.user.id;
    this.menuService.menuChange.subscribe((e: any) => {
      this.menuDeal(e);
      this.menus = e;
      this.getUserInfo();
      // 获取操作权限按钮
      this.getButtonsCode();
    });

    this.nbAvatarService.changeStream.subscribe(() => {
      this.getUserInfo();
      // 获取操作权限按钮
      this.getButtonsCode();
    });

    // this.menusService.change.subscribe((e: any) => {
    //   if (e.length > 0) {
    //     this.menusUsed = [];
    //     const menusUsed = this.getMenuSelect(e) || [];
    //     // let url;
    //     if (menusUsed?.length > 0) {
    //       // setTimeout(() => {
    //       //   const selectEach = menusUsed.filter((x: any) => x._selected);
    //       //   url = selectEach[0].link;
    //       //   AppReuseStrategy.deleteRouteSnapshot(url);
    //       // }, 500);
    //       // if (url) {
    //       //   this.router.navigate([url], { relativeTo: this.route });
    //       // }
    //     }
    //   }
    // });
    this.options = {
      logoExpanded: this.settings.app.logofull ? this.settings.app.logofull : this.options.logoExpanded,
      logoCollapsed: this.settings.app.logo ? this.settings.app.logo : this.options.logoCollapsed,
    };
    this.messageService.message.subscribe((msg: ShowyeMessageSummary) => {
      this.totalCount = msg.unread;
      this.totalMsgs = msg.data;
    });
  }

  getButtonsCode(): void {
    this.nbApi
      .myButton()
      .pipe(take(1))
      .subscribe((res: any) => {
        const data = res.data || [];
        const buttons = data.filter((x: any) => x.group === 3 && x.interfaceCode);
        this.buttonGroup = buttons.reduce((a: any, c: any) => ({ ...a, [c.interfaceCode]: true }), {});
        window.localStorage.setItem('buttonGroup', JSON.stringify(this.buttonGroup));
        // console.log(data, buttons, this.buttonGroup, 1111);
      });
  }

  // 获取被选中的菜单
  getMenuSelect(data: any): any[] {
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length > 0) {
          this.getMenuSelect(data[i].children);
        } else {
          this.menusUsed.push(data[i]);
        }
      }
    }
    return this.menusUsed;
  }

  // 菜单按钮权限不显示
  menuDeal(data: any[]): void {
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].group === 3) {
          data.splice(i, 1);
          i--;
        }
        if (data[i]?.children) {
          this.menuDeal(data[i].children);
        }
      }
    }
  }

  dumpMyMessage(type: number): void {
    this.messageService.click(type);
  }

  getCount(num: any): any {
    return !!num && num > 99 ? '99+' : !!num && num > 0 ? num : 0;
  }

  getUserInfo(): void {
    if (this.userId) {
      this.nbApi
        .userInfo(this.userId)
        .pipe(take(1))
        .subscribe((e: any) => {
          if (e.code === 200) {
            if (e.data?.avatar) {
              this.staffAvatar = this.downloadUrl + e.data.avatar;
            }
          }
        });
    }
  }

  onMenuChange(link: any): void {
    if (link === '/house') {
      const indexPath = window.localStorage.getItem('indexPath');
      this.router.navigate([indexPath]);
    }
  }
}
