import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
// layout
import { Exception404Component } from '@showye/showye-page-admin/layout-admin';
import { SHOWYE_PAGE_ADMIN_ROOT_ROUTE } from '@showye/showye-page-admin/routing';
import { SHOWYE_PAGE_FORM_ROOT_ROUTE } from '@showye/showye-page-form/routing';
import { SHOWYE_PAGE_FLOW_PROCESS_ROOT_ROUTE, SHOWYE_PAGE_FLOW_TASK_ROOT_ROUTE } from '@showye/showye-page-flow/routing';
import { ShowyePageAuthGuard } from '@showye/showye-page-base';
import { LayoutComponent } from '../layout/layout.component';
import { HouseDeveloperModule } from './stat/house/house-developer.module';
import { HouseStockComponent } from './stat/stock/house-stock.component';
import { ReportModule } from './patrol/report/report.module';

const indexPath = window.localStorage.getItem('indexPath');
const viewType = window.localStorage.getItem('viewType');

// @ts-ignore
const routes: Routes = [
  { path: 'blank', loadChildren: () => import('./blank/blank.module').then((m) => m.BlankModule) },
  { path: 'overview', loadChildren: () => import('./overview/overview.module').then((m) => m.OverviewModule) },
  { path: 'passport', loadChildren: () => import('./login/passport.module').then((m) => m.PassportModule) },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ShowyePageAuthGuard],
    canActivateChild: [ShowyePageAuthGuard],
    children: [
      { path: 'exception', children: [{ path: '404', component: Exception404Component }] },
      {
        path: 'design',
        children: [
          { path: '', redirectTo: 'process', pathMatch: 'full' },
          SHOWYE_PAGE_FLOW_PROCESS_ROOT_ROUTE,
          SHOWYE_PAGE_FORM_ROOT_ROUTE,
        ],
      },
      SHOWYE_PAGE_FLOW_TASK_ROOT_ROUTE,
      SHOWYE_PAGE_ADMIN_ROOT_ROUTE,
      //  在下面放置 业务模块 的内容
      { path: '', children: [{ path: '', redirectTo: indexPath + '', pathMatch: 'full' }] },
      // { path: '', children: [{ path: '', redirectTo: '/overview', pathMatch: 'full' }] },

      // 房源
      {
        path: 'house',
        children: [
          { path: '', redirectTo: indexPath + '', pathMatch: 'full' },
          // 工作台
          {
            path: 'work',
            children: [
              // 领导首页
              {
                path: 'leader',
                loadChildren: () => import('./workbench/leader/leader.module').then((m) => m.LeaderModule),
              },
              // 土地部门首页
              {
                path: 'land',
                loadChildren: () => import('./workbench/land/land.module').then((m) => m.LandModule),
              },
              // 计划运营部首页
              {
                path: 'plan',
                loadChildren: () => import('./workbench/plan/plan.module').then((m) => m.PlanModule),
              },
              // 其他人员首页
              {
                path: 'other',
                loadChildren: () => import('./workbench/other/other.module').then((m) => m.OtherModule),
              },
              // 全部申请
              {
                path: 'allApply',
                loadChildren: () => import('./all-apply/all-apply.module').then((m) => m.AllApplyModule),
              },
              // 我的申请
              { path: 'apply', loadChildren: () => import('./my/apply/apply.module').then((m) => m.ApplyModule) },
              // 草稿箱
              { path: 'draft', loadChildren: () => import('./draft/draft.module').then((m) => m.DraftModule) },
              // 我的消息
              {
                path: 'message',
                loadChildren: () => import('./my/message/message.module').then((m) => m.MessageModule),
              },
              // 我的资料
              {
                path: 'profile',
                loadChildren: () => import('./my/profile/profile.module').then((m) => m.ProfileModule),
              },
            ],
          },
          // 房源库
          {
            path: 'inventory',
            children: [
              {
                path: 'all',
                loadChildren: () => import('./inventory/all/all.module').then((m) => m.AllInventoryModule),
              },
              {
                path: 'land',
                loadChildren: () => import('./inventory/land/land.module').then((m) => m.LandInventoryModule),
              },
              {
                path: 'city',
                loadChildren: () => import('./inventory/city/city.module').then((m) => m.CityInventoryModule),
              },
              {
                path: 'update',
                loadChildren: () => import('./inventory/update/update.module').then((m) => m.UpdateInventoryModule),
              },
              {
                path: 'safeguard',
                loadChildren: () => import('./inventory/safeguard/safeguard.module').then((m) => m.SafeguardInventoryModule),
              },
              // 房源备案
              { path: 'record', loadChildren: () => import('./record/record.module').then((m) => m.RecordModule) },
              // 分配使用部门
              {
                path: 'distribute',
                loadChildren: () => import('./distribute/distribute.module').then((m) => m.DistributeModule),
              },
            ],
          },
          // 项目管理
          {
            path: 'project',
            children: [
              { path: 'all', loadChildren: () => import('./project/all/all.module').then((m) => m.AllProjectModule) },
              {
                path: 'land',
                loadChildren: () => import('./project/land/land.module').then((m) => m.LandProjectModule),
              },
              {
                path: 'city',
                loadChildren: () => import('./project/city/city.module').then((m) => m.CityProjectModule),
              },
              {
                path: 'update',
                loadChildren: () => import('./project/update/update.module').then((m) => m.UpdateProjectModule),
              },
              {
                path: 'safeguard',
                loadChildren: () => import('./project/safeguard/safeguard.module').then((m) => m.SafeguardProjectModule),
              },
            ],
          },
          // 房源状态管理
          {
            path: 'status',
            children: [
              {
                path: 'newenter',
                loadChildren: () => import('./newenter/enter.module').then((m) => m.EnterModule),
              },
              { path: 'deploy', loadChildren: () => import('./deploy/deploy.module').then((m) => m.DeployModule) },
              { path: 'use', loadChildren: () => import('./use/use.module').then((m) => m.UseModule) },
              {
                path: 'unfreeze',
                loadChildren: () => import('./unfreeze/unfreeze.module').then((m) => m.UnfreezeModule),
              },
              {
                path: 'sign',
                loadChildren: () => import('./sign/sign.module').then((m) => m.SignModule),
              },
              {
                path: 'newdeliver',
                loadChildren: () => import('./newdeliver/deliver.module').then((m) => m.DeliverModule),
              },
              {
                path: 'back',
                loadChildren: () => import('./back/back.module').then((m) => m.BackModule),
              },
              {
                path: 'return',
                loadChildren: () => import('./return/return.module').then((m) => m.ReturnModule),
              },
            ],
          },
          // 房源合同管理
          {
            path: 'contract',
            children: [
              {
                path: 'order',
                loadChildren: () => import('./contract/order/order.module').then((m) => m.OrderModule),
              },
              {
                path: 'deploy',
                loadChildren: () => import('./contract/alloc/alloc.module').then((m) => m.AllocModule),
              },
              {
                path: 'out',
                loadChildren: () => import('./contract/out/out.module').then((m) => m.OutModule),
              },
              {
                path: 'return',
                loadChildren: () => import('./contract/return/return.module').then((m) => m.ContractReturnModule),
              },
              // {
              //   path: 'deliver',
              //   loadChildren: () => import('./contract/order/order.module').then((m) => m.OrderModule),
              // },
              // {
              //   path: 'back',
              //   loadChildren: () => import('./contract/order/order.module').then((m) => m.OrderModule),
              // },
            ],
          },
          // 房源资金管理
          {
            path: 'money',
            children: [
              {
                path: 'housePay',
                loadChildren: () => import('./money/pay/money-pay.module').then((m) => m.MoneyPayModule),
              },
              {
                path: 'return',
                loadChildren: () => import('./money/return/money-return.module').then((m) => m.MoneyReturnModule),
              },
              {
                path: 'property',
                loadChildren: () => import('./money/property/money-property.module').then((m) => m.MoneyPropertyModule),
              },
              {
                path: 'patrol',
                loadChildren: () => import('./money/patrol/money-patrol.module').then((m) => m.MoneyPatrolModule),
              },
              {
                path: 'estimation',
                loadChildren: () => import('./money/estimation/money-estimation.module').then((m) => m.MoneyEstimationModule),
              },
            ],
          },
          // 基础数据维护
          {
            path: 'vindicate',
            children: [
              { path: 'build', loadChildren: () => import('./build/build.module').then((m) => m.BuildModule) },
              {
                path: 'developer',
                loadChildren: () => import('./developer/developer.module').then((m) => m.DeveloperModule),
              },
              { path: 'realty', loadChildren: () => import('./realty/realty.module').then((m) => m.RealtyModule) },
            ],
          },
          // 历史数据录入
          {
            path: 'history',
            children: [
              {
                path: 'historyData',
                loadChildren: () => import('./historyData/history.module').then((m) => m.HistoryModule),
              },
            ],
          },
        ],
      },
      // 巡查
      {
        path: 'patrol',
        children: [
          {
            path: '',
            redirectTo: viewType === 'patrolLeader' ? '/patrol/inspector' : '/patrol/district',
            pathMatch: 'full',
          },
          {
            path: 'check',
            loadChildren: () => import('./patrol/check/patrol-check.module').then((m) => m.PatrolCheckModule),
          },
          {
            path: 'district',
            loadChildren: () => import('./patrol/district/patrol-district.module').then((m) => m.PatrolDistrictModule),
          },
          {
            path: 'exception',
            loadChildren: () => import('./patrol/exception/patrol-exception.module').then((m) => m.PatrolExceptionModule),
          },
          {
            path: 'manager',
            loadChildren: () => import('./patrol/manager/patrol-manager.module').then((m) => m.PatrolManagerModule),
          },
          {
            path: 'person',
            loadChildren: () => import('./patrol/person/patrol-person.module').then((m) => m.PatrolPersonModule),
          },
          {
            path: 'stat',
            loadChildren: () => import('./patrol/stat/patrol-stat.module').then((m) => m.PatrolStatModule),
          },
          // 巡查统计
          {
            path: 'report',
            loadChildren: () => import('./patrol/report/report.module').then((m) => m.ReportModule),
          },
          {
            path: 'task',
            loadChildren: () => import('./patrol/task/patrol-task.module').then((m) => m.PatrolTaskModule),
          },
          {
            path: 'inspector',
            loadChildren: () => import('./inspector/task/inspector-task.module').then((m) => m.InspectorTaskModule),
          },
        ],
      },
      // 统计
      {
        path: 'stat',
        children: [
          { path: '', redirectTo: '/stat/company', pathMatch: 'full' },
          {
            path: 'project',
            children: [
              { path: '', redirectTo: 'house', pathMatch: 'full' },
              {
                path: 'house',
                loadChildren: () => import('./stat/project/project-house.module').then((m) => m.ProjectHouseModule),
              },
            ],
          },
          {
            path: 'house',
            loadChildren: () => import('./stat/house/house-developer.module').then((m) => m.HouseDeveloperModule),
          },
          {
            path: 'company/year',
            loadChildren: () => import('./stat/company-year/company-year.module').then((m) => m.CompanyYearModule),
          },
          {
            path: 'project/year',
            loadChildren: () => import('./stat/project-year/project-year.module').then((m) => m.ProjectYearModule),
          },
          {
            path: 'deploy',
            loadChildren: () => import('./stat/deploy/deploy-house.module').then((m) => m.DeployHouseModule),
          },
          {
            path: 'company-project',
            loadChildren: () => import('./stat/company-project/company-project.module').then((m) => m.CompanyProjectModule),
          },
          {
            path: 'out',
            loadChildren: () => import('./stat/out/out-house.module').then((m) => m.OutHouseModule),
          },
          {
            path: 'stock',
            loadChildren: () => import('./stat/stock/house-stock.module').then((m) => m.HouseStockModule),
          },
          {
            path: 'company',
            loadChildren: () => import('./stat/company/company-deliver.module').then((m) => m.CompanyDeliverModule),
          },
          {
            path: 'company-community',
            loadChildren: () => import('./stat/company-community/company-community.module').then((m) => m.CompanyCommunityModule),
          },
          {
            path: 'community',
            children: [
              { path: '', redirectTo: 'deliver', pathMatch: 'full' },
              {
                path: 'deliver',
                loadChildren: () => import('./stat/community/community-deliver.module').then((m) => m.CommunityDeliverModule),
              },
            ],
          },
          {
            path: 'money',
            loadChildren: () => import('./stat/house-money/house-money.module').then((m) => m.HouseMoneyModule),
          },
          {
            path: 'task-err',
            loadChildren: () => import('./stat/task/task-err.module').then((m) => m.TaskErrModule),
          },
        ],
      },
      // 设置
      {
        path: 'set',
        children: [
          { path: '', redirectTo: '/set/system/domain', pathMatch: 'full' },
          {
            path: 'system',
            children: [
              { path: '', redirectTo: 'all', pathMatch: 'full' },
              { path: 'domain', loadChildren: () => import('./set/domain/domain.module').then((m) => m.DomainModule) },
              { path: 'role', loadChildren: () => import('./set/role/role.module').then((m) => m.RoleModule) },
              { path: 'auth', loadChildren: () => import('./set/auth/auth.module').then((m) => m.AuthModule) },
              { path: 'user', loadChildren: () => import('./set/user/user.module').then((m) => m.UserModule) },
            ],
          },
          {
            path: 'other',
            children: [
              { path: '', redirectTo: 'sync', pathMatch: 'full' },
              { path: 'sync', loadChildren: () => import('./set/auth/auth.module').then((m) => m.AuthModule) },
              { path: 'update', loadChildren: () => import('./set/auth/auth.module').then((m) => m.AuthModule) },
              { path: 'log', loadChildren: () => import('./set/log/log.module').then((m) => m.LogModule) },
              { path: 'imp', loadChildren: () => import('./impHouse/impHouse.module').then((m) => m.ImpHouseModule) },
            ],
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule {}
