import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReuseService implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  // 用来判断跳转时是否需要存储页面
  from = '';
  to = '';
  // 要缓存的路由
  reusePaths = ['/patrol/task'];

  /** 进入路由触发，判断是否是同一路由 */
  shouldReuseRoute(to: ActivatedRouteSnapshot, from: ActivatedRouteSnapshot): boolean {
    if (from.routeConfig) {
      this.from = this.getPath(from);
    }
    if (to.routeConfig) {
      this.to = this.getPath(to);
    }
    return from.routeConfig === to.routeConfig;
  }

  /** 表示对所有路由允许复用 如果你有路由不想利用可以在这加一些业务逻辑判断 */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // 判断是否执行store
    const reuseFrom = this.from.split('/');
    const reuseTo = this.to.split('/');
    if (reuseFrom[1] === reuseTo[1]) {
      let isDetach = false;
      this.reusePaths.forEach((x: any) => {
        if (this.from === x) {
          isDetach = true;
        }
      });
      return isDetach;
    } else {
      return false;
    }
  }

  /** 当路由离开时会触发。按path作为key存储路由快照&组件当前实例对象 */
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    // 进行路由复用存储
    this.storedRouteHandles.set(this.getPath(route), detachedTree);
  }

  /** 从缓存中获取快照，若无则返回 null | */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    // console.log('获取快照', route, this.storedRouteHandles);
    let isRetrieve: DetachedRouteHandle | null = null;
    this.reusePaths.forEach((x: any) => {
      if (this.getPath(route) === x) {
        // 读取路由复用
        isRetrieve = this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
      }
    });
    return isRetrieve;
  }

  /** 若 path 在缓存中有的都认为允许还原路由 */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let isAttach = false;
    this.reusePaths.forEach((x: any) => {
      if (this.getPath(route) === x) {
        isAttach = true;
      }
    });
    return !!(isAttach && (this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle));
  }

  private getPath(route: any): string {
    return route._routerState.url;
  }
}
