import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterCacheService {
  // public outputStream: any;
  public outputStream: any = new Subject<any>();

  // onPathData(): Observable<any> {
  //   return this.outputStream.asObservable();
  // }

  constructor() {
    this.outputStream = new EventEmitter();
  }

  getModuleUrl(paramAll?: boolean): string {
    const currentUrl = window.location.hash;
    const url = currentUrl.split('#')[1];
    let urlType = '';
    if (currentUrl.indexOf(';') !== -1) {
      if (!paramAll) {
        const firstIndex = url.indexOf(';');
        const findUrl = url.slice(0, firstIndex);
        const lastIndex = findUrl.lastIndexOf('/');
        urlType = findUrl.slice(0, lastIndex);
      } else {
        const firstIndex = url.indexOf(';');
        const findUrl = url.slice(0, firstIndex);
        urlType = findUrl;
      }
      // console.log(urlType, 888);
    } else {
      urlType = url;
      // console.log(urlType, 999);
    }
    return urlType;
  }
}
