import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';
import { RouterCacheService } from '@service/router-cache.service';

interface IRouteConfigData {
  title: string;
  isSelected: boolean;
}

interface ICachedRoute {
  handle: DetachedRouteHandle;
  data: IRouteConfigData;
}

@Injectable()
export class AppReuseStrategy implements RouteReuseStrategy {
  private static routeCache = new Map<string, ICachedRoute>();
  private static pathData: any[] = []; // 展示标签数据;

  constructor(private routerCacheService: RouterCacheService) {}

  /** 进入路由触发，判断是否是同一路由 */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // console.log('进入路由触发，判断是否是同一路由', future.routeConfig === curr.routeConfig, future, curr);
    return future.routeConfig === curr.routeConfig;
  }

  /** 若 path 在缓存中有的都认为允许还原路由 */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // console.log('若 path 在缓存中有的都认为允许还原路由 ', route);
    const url = this.getFullRouteUrl(route);
    const data = this.getRouteData(route);

    // console.log(route, Object.entries(AppReuseStrategy.routeCache).length, data);
    if (Object.entries(AppReuseStrategy.routeCache).length === 0 && data?.title && url) {
      const showData = [{ title: data.title, path: url, isSelected: true }];
      this.routerCacheService.outputStream.emit({ data: showData, type: 1 });
    } else {
      AppReuseStrategy.pathData = [];
      for (const [key, val] of AppReuseStrategy.routeCache.entries()) {
        const each = { title: val.data.title, path: key, isSelected: false };
        each.isSelected = key === url;
        AppReuseStrategy.pathData.push(each);
      }
      if (url && data && !AppReuseStrategy.routeCache.has(url)) {
        AppReuseStrategy.pathData.push({ title: data.title, path: url, isSelected: true });
      }
      this.routerCacheService.outputStream.emit({ data: AppReuseStrategy.pathData, type: 1 });
    }
    // console.log('若 path 在缓存中有的都认为允许还原路由 ', data, url, AppReuseStrategy.routeCache, AppReuseStrategy.pathData);
    return AppReuseStrategy.routeCache.has(url);
  }

  /** 表示对所有路由允许复用 如果你有路由不想利用可以在这加一些业务逻辑判断，这里判断是否有data数据判断是否复用 */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // todo 暂时都不使用缓存
    if (!route.data.title) {
      return false;
    }
    // if (!route.routeConfig || route.routeConfig.loadChildren) {
    //   return false;
    // }
    // console.log('是否允许复用:', route);
    return false;
  }

  /** 当路由离开时会触发。按path作为key存储路由快照&组件当前实例对象 */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): any {
    const url = this.getFullRouteUrl(route);
    const data: any = this.getRouteData(route);
    // console.log('store', route, url, handle, data);
    if (url && handle) {
      AppReuseStrategy.routeCache.set(url, { handle, data });
    }
    // console.log(AppReuseStrategy.routeCache);
  }

  /** 从缓存中获取快照，若无则返回 null | */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null | any {
    // console.log('从缓存中获取快照，若无则返回null', route);
    const url = this.getFullRouteUrl(route);
    const data = this.getRouteData(route);
    // console.log(data);
    if (url && data && AppReuseStrategy.routeCache.has(url)) {
      // @ts-ignore
      return AppReuseStrategy.routeCache.get(url).handle;
    }
    return null;
  }

  private getFullRouteUrl(route: any): any {
    return route._routerState.url;
  }

  private getFullRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
    const paths = this.getRouteUrlPaths(route);
    const data = route.parent ? [...this.getFullRouteUrlPaths(route.parent), ...paths] : paths;
    // console.log(data);
    return data;
  }

  private getRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
    return route.url.map((urlSegment) => urlSegment.path);
  }

  private getRouteData(route: ActivatedRouteSnapshot): IRouteConfigData {
    // @ts-ignore
    return (route.routeConfig as IRouteConfigData) && (route.routeConfig.data as IRouteConfigData);
  }

  // 用于删除路由快照
  public static deleteRouteSnapshot(url: string): void {
    if (AppReuseStrategy.routeCache.has(url)) {
      AppReuseStrategy.routeCache.delete(url);
    }
  }

  // 清空路由快照
  public static deleteALLRouteSnapshot(): void {
    AppReuseStrategy.routeCache = new Map<string, ICachedRoute>();
  }
}
