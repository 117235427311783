// tslint:disable: no-duplicate-imports
// #region Http Interceptors
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GlobalConfigModule } from './global-config.module';
import { NbHomeGuard } from './services/nb-home.guard';
import { SharedModule } from './shared/shared.module';
import { STWidgetModule } from './shared/st-widget/st-widget.module';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { CommonModule, registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbFormModule } from '@showye/nbweb-input/nb-form';
import { ShowyePageBaseModule } from '@showye/showye-page-base';
import { DefaultInterceptor, StartupService } from '@core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { LayoutAdminModule } from '@showye/showye-page-admin/layout-admin';
import { RouteRoutingModule } from './routes/routes-routing.module';
import { NgxAmapModule } from 'ngx-amap';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { LayoutModule } from './layout/layout.module';
import { RouteReuseStrategy } from '@angular/router';
import { NzTagModule } from 'ng-zorro-antd/tag';

const INTERCEPTOR_PROVIDES = [{ provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true }];

export function StartupServiceFactory(startupService: StartupService): () => Promise<void> {
  return () => startupService.load();
}

const APPINIT_PROVIDES = [
  StartupService,
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [StartupService],
    multi: true,
  },
  NbHomeGuard,
];

registerLocaleData(zh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzSpinModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GlobalConfigModule.forRoot(),
    CoreModule,
    SharedModule,
    RouteRoutingModule,
    STWidgetModule,
    NzMessageModule,
    NzNotificationModule,
    NzFormModule,
    NzInputModule,
    NzAlertModule,
    NzGridModule,
    NzAvatarModule,
    NzPaginationModule,
    NzTagModule,
    // ...FORM_MODULES,
    NgxWebstorageModule.forRoot(),
    FormsModule,
    NbFormModule.forRoot({
      serviceURL: environment.host + environment.path + '/form/external/request/call',
    }),
    ShowyePageBaseModule.forRoot({
      host: environment.host,
      path: environment.path,
      wsHost: environment.wsHost,
      iconUrl: environment.iconUrl,
      loginPath: '/passport/login',
      passportPath: '/passport',
    }),
    LayoutAdminModule,
    LayoutModule,
    NgxAmapModule.forRoot({
      apiKey: environment.amapKey,
    }),
  ],
  providers: [
    // ...LANG_PROVIDES,
    ...INTERCEPTOR_PROVIDES,
    ...APPINIT_PROVIDES,
    { provide: NZ_I18N, useValue: zh_CN },
    // { provide: RouteReuseStrategy, useClass: AppReuseStrategy },
    // { provide: RouteReuseStrategy, useClass: ReuseService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
