import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
/**
 * excel 导出服务
 */
export class NbXlsxService {
  constructor(private date: DatePipe, private currency: CurrencyPipe, private percent: PercentPipe) {}

  public export(data: any[], cols?: any[], filename?: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.parseData(data, cols));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename ? (filename.indexOf('.xlsx') >= 0 ? filename : filename + '.xlsx') : 'export.xlsx');
  }

  public parseData(data: any[], cols?: any[]): any {
    const colMap: any = cols
      ? cols.filter((x: any) => !!x.index && x.index.length > 0).reduce((a: any, c: any) => ({ ...a, [c.index]: c }), {})
      : {};
    const st: any = [];
    if (data && data.length > 0) {
      const keys = !!cols ? cols.map((c: any) => c.index) : Object.keys(data[0]).filter((k: any) => !!colMap[k]);
      const titles = keys.map((k: any) => (colMap[k] ? colMap[k].title : k));
      st.push(titles);
      data.forEach((d: any) =>
        st.push(
          keys.map((k: any) => {
            return this.convertDataItem(k, d, colMap[k]);
          }),
        ),
      );
    }
    return st;
  }

  public convertDataItem(key: string, data: any, col: any): any {
    if (!key) {
      return data;
    }
    const x = this.getNested(key.split('.'), data);
    switch (col.type) {
      case 'tag': {
        return x ? (col.tag && col.tag[x] ? col.tag[x].text : x) : '';
      }
      case 'enum': {
        return x ? (col.enum ? col.enum[x] : x) : '';
      }
      case 'date': {
        return x ? this.date.transform(x, col.dateFormat ? col.dateFormat : 'yyyy-MM-dd HH:mm:ss') : '';
      }
      case 'currency': {
        return x ? this.currency.transform(x, 'CNY', 'symbol-narrow') : '';
      }
      case 'percent': {
        return x ? this.percent.transform(x, '0.0-2') : '';
      }
      default: {
        return x ? x : '';
      }
    }
  }

  public getNested(path: string[], data: any): any {
    if (!data) {
      return null;
    }
    if (path.length <= 0) {
      return data;
    }
    if (path.length === 1) {
      return data[path[0]];
    }
    return this.getNested(path.splice(1), data[path[0]]);
  }
}
