import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'optinFilter'})
export class OptinFilterPipe implements PipeTransform {
  transform(items: any[], op: string, idx: number, val: any[]): any {
    if (!items || !op || !val || val.length <= 0){
      return items;
    }
    return items.filter(e => !!e[op] && (val.indexOf(e[op]) < 0 || val[idx] == e[op]));
  }
}

