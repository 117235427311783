export function colOptionsToColumns(opts: any[]): any[] {
  return opts.map((x: any) => {
    const t: any = {
      title: x.label,
      index: x.value,
      type: x.type,
      width: x.width,
      statistical: x.statistical,
      iif: () => !!opts.find((w) => w.value === x.value && w.checked),
      filter:
        x.filter === true
          ? {
              type: 'keyword',
              fn: (filter: any, record: any) =>
                !filter.value ||
                (record[x.value] !== null && record[x.value] !== undefined && (record[x.value] + '').indexOf(filter.value) !== -1),
            }
          : x.filter
          ? {
              menus: x.filter.menus,
              fn: (filter: any, record: any) => record[x.value] === filter.value,
              multiple: x.filter.multiple,
            }
          : null,
    };
    if (x.render) {
      t.render = x.render;
    }
    if (x.sort) {
      t.sort = {
        compare: (a: any, b: any) => (a[x.value] < b[x.value] ? -1 : a[x.value] > b[x.value] ? 1 : 0),
      };
    }
    if (x.type === 'tag' || x.type === 'tags') {
      t.tag = x.tag;
    }
    if (x.type === 'enum' && !!x.enum) {
      t.enum = x.enum;
    }
    if (x.type === 'date') {
      t.dateFormat = x.dateFormat;
    }
    if (x.type === 'number' || x.type === 'percent') {
      t.numberDigits = x.numberDigits;
    }
    if (x.type === 'link') {
      t.click = x.click;
    }
    return t;
  });
}
