import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'optFilter'})
export class OptFilterPipe implements PipeTransform {
  transform(items: any[], op: string, val: any): any {
    if (!items){
      return items;
    }
    if (!op || !val) {
      return [];
    }
    return items.filter(e => !!e[op] && e[op] == val);
  }
}

