import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class API {
  public HOST = environment.host + environment.path;
  public WX_MP_APP_ID = environment.wxMpId;

  public LIST_CHILDREN_BY_DOMAIN = (id: any) => `${this.HOST}/company/organise/children/${id}`;
  public LIST_STAFF_BY_DOMAIN = (id: any) => `${this.HOST}/company/organise/staffs/${id}`;
  public BY_POSITION_ID = (positionId: any) => `${this.HOST}/hr/staff/byPositionId/${positionId}`;
  public BY_ROLE_ID = (roleId: any) => `${this.HOST}/hr/staff/byRoleId/${roleId}`;
  public MY_CHANGE_PASSWORD = `${this.HOST}/admin/user/password/reset`;
  public MY_UPDATE_INFO = `${this.HOST}/my/info/update`;
  // 用户权限按钮集合
  public MY_BUTTONS = `${this.HOST}/admin/menu/myButtons`;

  // 枚举字典相关
  public ENUM_LIST = `${this.HOST}/core/enum/list`;
  // 获取公司列表
  public GET_COMPANY_LIST = `${this.HOST}/admin/domain/getCompanyList`;
  // 获取公司下的部门信息
  public LIST_BY_COMPANY_ID = (companyId: any) => `${this.HOST}/admin/domain/listByCompanyId/${companyId}`;
  // 获取个人主体信息
  public GET_MY_COMPANY = `${this.HOST}/admin/domain/getMyCompany`;
  // 根据部门获取员工信息
  public GET_USER_LIST_BY_DOMAIN_ID = (domainId: any) => `${this.HOST}/admin/user/getUserListByDomainId/${domainId}`;
  // 根据角色获取员工信息
  public GET_USER_LIST_BY_ROLE_ID = (roleId: any) => `${this.HOST}/admin/user/getUserListByRoleId/${roleId}`;
  // 根据主体和用户id查询所属部门
  public GET_DOMAIN_ID_BY_COMPANY_AND_USER_ID = `${this.HOST}/admin/domain/getDomainIdByCompanyAndUserId`;
  // 授权登录
  public LOGIN_BY_TOKEN = (token: any) => `${this.HOST}/admin/user/loginByToken?token=${token}`;

  /**
   * 权限组管理
   */
  // 权限组列表
  public AUTH_GROUP_LIST = `${this.HOST}/admin/authGroup/list`;
  // 新增权限组
  public AUTH_GROUP_ADD = `${this.HOST}/admin/authGroup/add`;
  // 修改权限组
  public AUTH_GROUP_EDIT = `${this.HOST}/admin/authGroup/edit`;
  // 删除权限组
  public AUTH_GROUP_DELETE = (id: any) => `${this.HOST}/admin/authGroup/delete/${id}`;

  /**
   * 用户管理
   */
  // 用户列表下拉选择
  public GET_USER_LIST = `${this.HOST}/admin/user/getUserList`;
  // 根据主体选择该主体下面的人员下拉选择
  public GET_USER_LIST_BY_COMPANYID = `${this.HOST}/admin/user/getUserListByCompanyId`;
  // 用户列表
  public USER_LIST = `${this.HOST}/admin/user/list`;
  public ADD_USER = `${this.HOST}/admin/user/add`;
  public EDIT_USER = `${this.HOST}/admin/user/edit`;
  // 编辑用户头像
  public EDIT_AVATAR = `${this.HOST}/admin/user/editAvatar`;
  public DELETE_USER = (id: any) => `${this.HOST}/admin/user/delete/${id}`;
  public USER_INFO = (id: any) => `${this.HOST}/admin/user/info/${id}`;
  // 重置员工登录密码
  public INIT_PWD = (id: any) => `${this.HOST}/admin/user/initPwd/${id}`;

  /**
   * 行政区
   */
  // 行政区树
  public DISTRICT_TREE = `${this.HOST}/district/tree`;

  /**
   * 项目管理
   */
  // 列表查询
  public LIST_PROJECT = `${this.HOST}/project/list`;
  // 新增
  public ADD_PROJECT = `${this.HOST}/project/add`;
  // 编辑
  public EDIT_PROJECT = `${this.HOST}/project/edit`;
  // 删除
  public DEL_PROJECT = `${this.HOST}/project/del`;
  // 详情
  public INFO_PROJECT = `${this.HOST}/project/info`;
  // 单个项目统计
  public STAT_SINGLE_PROJECT = `${this.HOST}/project/statistics/single`;
  // 所有项目统计
  public STAT_ALL_PROJECT = `${this.HOST}/project/statistics/all`;
  // 项目状态修改
  public CHANGE_STATUS = `${this.HOST}/project/changeStatus`;
  // 流转记录列表
  public FLOW_RECORD_BY_PROJECT = `${this.HOST}/apply/flowRecordByProject`;
  // 流转记录列表 ---房源集合
  public HOUSE_IDS_BY_PROJECT = `${this.HOST}/apply/houseIdsByProject`;
  // 更新项目进展
  public UPDATE_PROJECT_PROGRESS = `${this.HOST}/project/updateProgress`;
  // 查看楼盘调配信息
  public ALLOC_INFO_BY_PROJECT = `${this.HOST}/project/allocInfo`;
  // 查看项目房源统析
  public PROJECT_HOUSE_COUNT = `${this.HOST}/statistics/project/projectHouseCount`;
  // 下载项目房源统析
  public DOWN_PROJECT_HOUSE_COUNT = `${this.HOST}/statistics/project/downProjectHouseCount`;
  // 查看去化情况统析-去化情况汇总
  public COMPANY_HOUSE_COUNT = `${this.HOST}/statistics/project/companyHouseCount`;
  // 下载去化情况统析-去化情况汇总
  public DOWN_COMPANY_HOUSE_COUNT = `${this.HOST}/statistics/project/downCompanyHouseCount`;
  // 查看去化情况详表-去化情况详表
  public COMMUNITY_HOUSE_INFO_COUNT = `${this.HOST}/statistics/project/communityHouseInfoCount`;
  // 下载去化情况详表-去化情况详表
  public DOWN_COMMUNITY_HOUSE_INFO_COUNT = `${this.HOST}/statistics/project/downCommunityHouseInfoCount`;

  // 查看项目房源统析-各项目房源使用情况明细表-按使用楼盘分
  public COMPANY_COMMUNITY_COUNT = `${this.HOST}/statistics/project/companyCommunityCount`;
  // 下载 项目房源统析-各项目房源使用情况明细表-按使用楼盘分
  public DOWN_COMPANY_COMMUNITY_COUNT = `${this.HOST}/statistics/project/downCompanyCommunityCount`;

  // 查看调配房源统计
  public DEPLOY_HOUSE_COUNT = `${this.HOST}/statistics/project/deployHouseCount`;
  // 下载调配房源统计
  public DOWN_DEPLOYHOUSE_COUNT = `${this.HOST}/statistics/project/downDeployHouseCount`;
  // 查看主体调配房源统计
  public COMPANY_PROJECT_COUNT = `${this.HOST}/statistics/project/companyProjectHouseCount`;
  // 下载主体调配房源统计
  public DOWN_COMPANY_PROJECT = `${this.HOST}/statistics/project/downCompanyProjectHouseCount`;
  // 查看数据统计分析
  public HOUSE_DEVELOPER_TYPE_COUNT = `${this.HOST}/statistics/project/houseDeveloperTypeCount`;
  // 下载数据统计分析
  public DOWN_HOUSE_DEVELOPER_TYPE_COUNT = `${this.HOST}/statistics/project/downHouseDeveloperTypeCount`;
  // 查看资金维度分析
  public HOUSE_MONEY_COUNT = `${this.HOST}/statistics/project/houseMoneyCount`;
  // 下载资金维度分析
  public DOWN_HOUSE_MONEY_COUNT = `${this.HOST}/statistics/project/downHouseMoneyCount`;
  // 查看 异常房源分析
  public TASK_ERR_COUNT = `${this.HOST}/statistics/project/taskErrCount`;
  // 下载异常房源分析
  public DOWN_TASK_ERR_COUNT = `${this.HOST}/statistics/project/downTaskErrCount`;
  // 查看 未使用房源统计
  public HOUSE_STOCK_COUNT = `${this.HOST}/statistics/project/communityHouseCount`;
  // 下载 未使用房源统计
  public DOWN_HOUSE_STOCK_COUNT = `${this.HOST}/statistics/project/downCommunityHouseCount`;
  // 查看外部调剂统计
  public OUT_COMMUNITY_COUNT = `${this.HOST}/statistics/project/outCommunityCount`;
  // 下载外部调剂统计
  public DOWN_OUTCOMMUNITY_COUNT = `${this.HOST}/statistics/project/downOutCommunityCount`;
  // 查看未使用房源统计
  public COMMUNITY_HOUSE_COUNT = `${this.HOST}/statistics/project/communityHouseCount`;
  // 下载未使用房源统计
  public DOWN_COMMUNITY_HOUSE_COUNT = `${this.HOST}/statistics/project/downCommunityHouseCount`;
  // 查看年度汇总统计
  public COMPANY_HOUSE_COUNT_BY_YEAR = `${this.HOST}/statistics/year/companyHouseCountByYear`;
  // 下载年度汇总统计
  public DOWN_COMPANY_HOUSE_COUNT_BY_YEAR = `${this.HOST}/statistics/year/downCompanyHouseCountByYear`;
  // 查看 项目维度安置房源年度使用情况明细表
  public PROJECT_HOUSE_COUNT_BY_YEAR = `${this.HOST}/statistics/year/projectHouseCountByYear`;
  // 下载 项目维度安置房源年度使用情况明细表
  public DOWN_PROJECT_HOUSE_COUNT_BY_YEAR = `${this.HOST}/statistics/year/downProjectHouseCountByYear`;
  // 查询项目未添加节点
  public GET_NODE_NAME_LIST_BY_PROJECT_ID = (projectId: any) => `${this.HOST}/project/getNodeNameListByProjectId/${projectId}`;
  // 项目节点列表
  public NODE_LIST = (projectId: any) => `${this.HOST}/project/node/listByProjectId/${projectId}`;
  // 新增项目节点
  public NODE_ADD = `${this.HOST}/project/node/add`;
  // 编辑项目节点
  public NODE_EDIT = `${this.HOST}/project/node/edit`;
  // 查询项目节点详情
  public NODE_INFO = (nodeId: any) => `${this.HOST}/project/node/info/${nodeId}`;
  // 删除项目节点
  public NODE_DELETE = (nodeId: any) => `${this.HOST}/project/node/delete/${nodeId}`;
  // 导出项目列表
  public DOWNLOAD_PROJECT_LIST = `${this.HOST}/project/downloadProjectList`;
  // 导出下载项目所属主体所有楼盘
  public DOWNLOAD_PROJECT_HOUSE_BY_COMPANY_ID = `${this.HOST}/project/downloadHouseByCompanyId`;
  // 导出项目内楼盘列表
  public DOWNLOAD_PROJECT_COMMUNITY_LIST = `${this.HOST}/project/downloadProjectCommunityList`;
  public DOWNLOAD_PROJECT_HOUSE_LIST = `${this.HOST}/project/downloadProjectHouseList`;
  public DOWNLOAD_PROJECT_DELIVER_LIST = `${this.HOST}/project/downloadProjectDeliverList`;
  public DOWNLOAD_HOUSE_USE = `${this.HOST}/community/downloadHouseUse`;
  public DOWNLOAD_HOUSE_LIST = `${this.HOST}/house/downloadHouseList`;
  public DOWNLOAD_SEARCH_HOUSE_LIST = `${this.HOST}/house/downloadSearchHouseList`;
  public DOWNLOAD_HOUSE_LIST_BY_CONTRACT = `${this.HOST}/house/downloadHouseListByContract`;
  public DOWNLOAD_TASK_RECORD_LIST = `${this.HOST}/patrol/task/downloadTaskRecordList`;
  public DOWNLOAD_TASK_RECORD_LIST_FU = `${this.HOST}/patrol/task/downloadTaskRecordListFu`;
  public DOWNLOAD_HOUSE_DATA_BY_COMMUNITY = `${this.HOST}/house/downloadHouseDataByCommunity`;
  // 修改房源面积等
  public IMP_HOUSE = `${this.HOST}/hseHistory/updateHouse`;
  // 修改房源项目
  public IMP_HOUSE_PROJECT = `${this.HOST}/hseHistory/updateHouseProject`;
  //  修改房源价格
  public IMP_HOUSE_PRICE = `${this.HOST}/hseHistory/updateHousePrice`;
  //  修改房源冻结原因
  public IMP_HOUSE_SEASON = `${this.HOST}/hseHistory/updateFreezeSeason`;
  //  修改用户OAID
  public IMP_USER_OAID = `${this.HOST}/hseHistory/updateUserOAId`;

  //  更新历史录入征收户到房源库
  public UPD_HOUSE_LEVYNAME = `${this.HOST}/hseHistory/updateLevyName`;
  //  更新更新统计面积
  public UPD_HOUSE_AREA = `${this.HOST}/hseHistory/updateStaArea`;
  /**
   * 房源订购
   */
  // 房源订购新增
  public ENTER_ADD = `${this.HOST}/enter/add`;
  // 房源订购编辑
  public ENTER_EDIT = `${this.HOST}/enter/edit`;
  // 查看房源订购详情
  public ENTER_VIEW = `${this.HOST}/enter/view`;
  // 房源订购列表
  public ENTER_LIST = `${this.HOST}/enter/list`;
  //  修改合同日期
  public CHANGE_CONTRACT_DATE = `${this.HOST}/apply/changeContractDate`;
  // 项目内房源回款记录
  public MONEY_RETURNED = `${this.HOST}/project/moneyReturned`;
  // 更新OA 数据
  public UPD_OA_STATUS = `${this.HOST}/apply/updateOAStatus`;
  // 更新流转记录
  public UPD_HOUSE_EXCHANGE = `${this.HOST}/hseHistory/addHistoryExchange`;
  // 更新审批通过
  public UPD_APPLY_NO = `${this.HOST}/apply/updApplyNo`;

  /**
   * 房源管理
   */
  // 按楼盘查询分期
  public GET_REGION_BY_COMMUNITY = (id: any) => `${this.HOST}/house/getRegionByCommunity?communityId=${id}`;
  // 按楼盘查询楼栋
  public GET_BUILDING_BY_REGION_AND_COMMUNITY = `${this.HOST}/house/getBuildingByRegionAndCommunity`;
  // 房源列表
  public HOUSE_LIST = `${this.HOST}/house/list`;
  // 房源列表根据房源id查
  public HOUSE_LIST_BY_IDS = `${this.HOST}/house/listByIds`;
  // 包含调剂到其他主体的房源列表
  public LIST_WITH_OUT_HOUSE = `${this.HOST}/house/listWithOutHouse`;
  // 房源列表(弹窗用快速版)
  public HOUSE_LIST_FAST = `${this.HOST}/house/listFast`;
  // 房源列表(弹窗巡查)
  public HOUSE_LIST_PATROL = `${this.HOST}/house/listPatrol`;
  // 项目房源列表(可分页)
  public HOUSE_LIST_BY_PRODUCT = `${this.HOST}/house/houseListByProduct`;
  // 项目房源统计
  public HOUSE_STAT_BY_PRODUCT = `${this.HOST}/house/houseStatByProduct`;
  // 查看房源详情
  public VIEW_HOUSE = `${this.HOST}/house/view`;
  // 楼盘房源树
  public HOUSE_TREE = (id: any) => `${this.HOST}/house/tree?communityId=${id}`;
  // 按楼盘楼栋查询单元
  public GET_UNIT_BY_COMMUNITY_AND_BUILDING = `${this.HOST}/house/getUnitByCommunityAndBuilding`;
  // 按楼盘楼栋查询楼层
  public GET_FLOOR_BY_COM_AND_BUILD = `${this.HOST}/house/getFloorByComAndBuild`;
  // 房源统计
  public HOUSE_STATISTICS = `${this.HOST}/house/houseStatistics`;
  // 编辑房源备注
  public EDIT_REMARK = `${this.HOST}/house/editRemark`;

  /**
   * 房源录入
   */
  // 列表查询
  public LIST_ENTER = `${this.HOST}/enter/list`;
  // 新增
  public ADD_ENTER = `${this.HOST}/enter/add`;
  // 编辑
  public EDIT_ENTER = `${this.HOST}/enter/edit`;
  // 详情
  public VIEW_ENTER = `${this.HOST}/enter/view`;
  // 审核
  public CHECK_ENTER = `${this.HOST}/enter/check`;
  // 导入
  public IMP_ENTER = `${this.HOST}/enter/imp`;
  // 选择器列表
  public SELECT_ENTER = `${this.HOST}/enter/select`;
  // OA日志
  public VIEW_LOG = `${this.HOST}/apply/applyLogs`;
  // 内部操作日志
  public VIEW_LOCAL_LOG = `${this.HOST}/apply/localLogs`;
  // 历史-驳回
  public REJECT_ENTER = `${this.HOST}/enter/checkHistory`;
  // 历史-通过
  public PASS_ENTER = `${this.HOST}/enter/checkHistory`;

  /**
   * 房源调配
   */
  // 列表查询
  public LIST_ALLOC = `${this.HOST}/alloc/list`;
  // 新增
  public ADD_ALLOC = `${this.HOST}/alloc/add`;
  // 编辑
  public EDIT_ALLOC = `${this.HOST}/alloc/edit`;
  // 详情
  public VIEW_ALLOC = `${this.HOST}/alloc/view`;
  // 审核
  public CHECK_ALLOC = `${this.HOST}/alloc/check`;
  // 选择器列表
  public SELECT_ALLOC = `${this.HOST}/alloc/select`;
  // 根据关联ids查询调配房源信息
  public GET_HOUSE_LIST_ALLOC = `${this.HOST}/alloc/getHouseListByIds`;
  // 成本分摊计算
  public GET_COMPUTE_ALLOC = `${this.HOST}/alloc/compute`;
  // 历史-驳回
  public REJECT_ALLOC = `${this.HOST}/alloc/checkHistory`;
  // 历史-通过
  public PASS_ALLOC = `${this.HOST}/alloc/checkHistory`;
  /**
   * 房源分配
   */
  // 列表查询
  public LIST_DISTRIBUTION = `${this.HOST}/distribution/list`;
  // 新增
  public ADD_DISTRIBUTION = `${this.HOST}/distribution/add`;
  // 编辑
  public EDIT_DISTRIBUTION = `${this.HOST}/distribution/edit`;
  // 详情
  public VIEW_DISTRIBUTION = `${this.HOST}/distribution/view`;
  // 审核
  public CHECK_DISTRIBUTION = `${this.HOST}/distribution/check`;

  /**
   * 房源调剂
   */
  // 列表查询
  public LIST_OUT = `${this.HOST}/out/list`;
  // 新增
  public ADD_OUT = `${this.HOST}/out/add`;
  // 编辑
  public EDIT_OUT = `${this.HOST}/out/edit`;
  // 详情
  public VIEW_OUT = `${this.HOST}/out/view`;
  // 审核
  public CHECK_OUT = `${this.HOST}/out/check`;
  // 选择器列表
  public SELECT_OUT = `${this.HOST}/out/select`;
  // 成本分摊计算
  public GET_COMPUTE_OUT = `${this.HOST}/out/compute`;
  // 审核
  public ADD_FILE_OUT = `${this.HOST}/out/addFile`;
  // 历史-驳回
  public REJECT_OUT = `${this.HOST}/out/checkHistory`;
  // 历史-通过
  public PASS_OUT = `${this.HOST}/out/checkHistory`;

  public LIST_BY_OA = `${this.HOST}/apply/getOAInfo`;
  /**
   * 房源签约过审核
   */
  // 列表查询
  public LIST_SIGN = `${this.HOST}/sign/list`;
  // 新增
  public ADD_SIGN = `${this.HOST}/sign/add`;
  // 编辑
  public EDIT_SIGN = `${this.HOST}/sign/edit`;
  // 详情
  public VIEW_SIGN = `${this.HOST}/sign/view`;
  // 审核
  public CHECK_SIGN = `${this.HOST}/sign/check`;
  // 历史-驳回
  public REJECT_SIGN = `${this.HOST}/sign/checkHistory`;
  // 历史-通过
  public PASS_SIGN = `${this.HOST}/sign/checkHistory`;

  /**
   * 对接清册
   */
  // 列表查询
  public LIST_DELIVER = `${this.HOST}/deliver/list`;
  // 新增
  public ADD_DELIVER = `${this.HOST}/deliver/add`;
  // 编辑
  public EDIT_DELIVER = `${this.HOST}/deliver/edit`;
  // 详情
  public VIEW_DELIVER = `${this.HOST}/deliver/view`;
  // 审核
  public CHECK_DELIVER = `${this.HOST}/deliver/check`;
  // 去化记录列表
  public DELIVER_LIST_BY_PROJECT = `${this.HOST}/deliver/getDeliverListByProject`;
  // 历史-驳回
  public REJECT_DELIVER = `${this.HOST}/deliver/checkHistory`;
  // 历史-通过
  public PASS_DELIVER = `${this.HOST}/deliver/checkHistory`;
  // 征收户
  public LEVY_DELIVER = `${this.HOST}/deliver/getLevyList`;

  /**
   * 房源退回
   */
  // 列表查询
  public LIST_BACK = `${this.HOST}/back/list`;
  // 新增
  public ADD_BACK = `${this.HOST}/back/add`;
  // 编辑
  public EDIT_BACK = `${this.HOST}/back/edit`;
  // 详情
  public VIEW_BACK = `${this.HOST}/back/view`;
  // 审核
  public CHECK_BACK = `${this.HOST}/back/check`;
  // 成本分摊计算
  public GET_COMPUTE_BACK = `${this.HOST}/back/compute`;
  // 历史-驳回
  public REJECT_BACK = `${this.HOST}/back/checkHistory`;
  // 历史-通过
  public PASS_BACK = `${this.HOST}/back/checkHistory`;
  // 添加补充协议
  public ADD_FILE_BACK = `${this.HOST}/back/addFile`;
  // 添加补充说明
  public ADD_REMARK_BACK = `${this.HOST}/back/addRemark`;
  /**
   * 房源退订
   */
  // 列表查询
  public LIST_RETURN = `${this.HOST}/return/list`;
  // 新增
  public ADD_RETURN = `${this.HOST}/return/add`;
  // 编辑
  public EDIT_RETURN = `${this.HOST}/return/edit`;
  // 详情
  public VIEW_RETURN = `${this.HOST}/return/view`;
  // 审核
  public CHECK_RETURN = `${this.HOST}/return/check`;
  // 成本分摊计算
  public GET_COMPUTE_RETURN = `${this.HOST}/return/compute`;
  // 历史-驳回
  public REJECT_RETURN = `${this.HOST}/return/checkHistory`;
  // 历史-通过
  public PASS_RETURN = `${this.HOST}/return/checkHistory`;

  /**
   * 历史数据录入
   */
  // 列表查询
  public LIST_HISTORY = `${this.HOST}/hseHistory/list`;
  // 新增
  public ADD_HISTORY = `${this.HOST}/hseHistory/add`;
  // 编辑
  public EDIT_HISTORY = `${this.HOST}/hseHistory/edit`;
  // 详情
  public VIEW_HISTORY = `${this.HOST}/hseHistory/view`;
  // 导入验证
  public IMP_HISTORY = `${this.HOST}/hseHistory/imp`;
  // 删除导入房源
  public DEL_HISTORY_HOUSEID = `${this.HOST}/hseHistory/del`;
  // 导出历史房源错误信息生成错误数据返回excel路径
  public EXP_HISTORY_ERROR = `${this.HOST}/hseHistory/generateExcel`;
  // 导出历史房源错误信息-查询错误数据返回excel路径
  public EXP_HISTORY_ERROR_BY_ID = `${this.HOST}/hseHistory/generateExcelById`;
  // 驳回
  public REJECT_HISTORY = `${this.HOST}/hseHistory/reject`;
  // 通过
  public COMPLETE_HISTORY = `${this.HOST}/hseHistory/complete`;
  // 删除
  public DEL_HISTORY = `${this.HOST}/hseHistory/delete`;
  // 导出土地部生成数据返回excel路径
  public EXP_LAND_DATA = `${this.HOST}/hseHistory/generateLanDataExcel`;
  // 导出现在房源数据返回excel路径
  public EXP_BASE_DATA = `${this.HOST}/hseHistory/generateBaseDataExcel`;
  // 项目房源使用情况明细表
  public PROJECT_HOUSING_USAGE_DATA = `${this.HOST}/house/report/projectHousingUsageInfo`;

  // 新增
  public SAVE_HOUSE_EXCHANGE = `${this.HOST}/hseHistory/saveHouseExchange`;
  // /**
  //  * 房源交付
  //  */
  // // 列表查询
  // public LIST_DELIVER = `${this.HOST}/deliver/list`;
  // // 根据项目ID 查询已交付列表查询
  // public GET_DELIVER_HOUSE_INFO_BY_PROJECT_ID = `${this.HOST}/deliver/getDeliverHouseInfoByProjectId`;
  // // 新增
  // public ADD_DELIVER = `${this.HOST}/deliver/add`;

  // /**
  //  * 房源回退
  //  */
  // // 列表查询
  // public LIST_BACK = `${this.HOST}/back/list`;
  // // 新增
  // public ADD_BACK = `${this.HOST}/back/add`;

  /**
   * 开发商管理
   */
  // 列表查询
  public LIST_DEVELOPER = `${this.HOST}/developer/getDeveloperList`;
  // 新增
  public ADD_DEVELOPER = `${this.HOST}/developer/add`;
  // 编辑
  public EDIT_DEVELOPER = `${this.HOST}/developer/edit`;
  // 详情
  public VIEW_DEVELOPER = `${this.HOST}/developer/view`;
  // 删除
  public DEL_DEVELOPER = `${this.HOST}/developer/delete`;
  /**
   * 征收户管理
   */
  // 根据项目查看征收户清单
  public GET_LEVY_LIST_BY_PROJECT = `${this.HOST}/levy/getLevyListByProject`;
  // 新增征收户
  public ADD_LEVY = `${this.HOST}/levy/add`;
  // 修改征收户
  public EDIT_LEVY = `${this.HOST}/levy/edit`;
  // 删除征收户
  public DELETE_LEVY = (id: any) => `${this.HOST}/levy/delete?id=${id}`;
  // 批量导入征收户
  public BATCH_IMPORT_LEVY = `${this.HOST}/levy/batchImport`;
  // 批量导入后新增楼盘分区管理-导入房源
  public ADD_ENTER_BATCH = `${this.HOST}/enterBatch/add`;
  // 批量导入后新增楼盘分区管理-导入房源验证
  public IMP_ENTER_BATCH = `${this.HOST}/enterBatch/imp`;
  // 楼盘导出
  public EXPORT_COMMUNITY = (ids: any) => `${this.HOST}/community/exportCommunity?communityIdList=${ids}`;

  /**
   * 我的申请
   */
  // 列表查询
  public LIST_APPLY = `${this.HOST}/apply/list`;
  // 我的申请列表
  public LIST_MY_APPLY = `${this.HOST}/homePage/myApplicationList`;
  // 撤销
  public WITHDRAW_APPLY = (id: any) => `${this.HOST}/apply/withdraw?id=${id}`;
  // 删除
  public DEL_APPLY = (id: any) => `${this.HOST}/apply/del?id=${id}`;
  // 所有申请列表
  public LIST_APPLICATION = `${this.HOST}/homePage/applicationList`;
  // 查询是否可提交
  public GET_APPLY_SUBMIT = `${this.HOST}/apply/getSubmit`;

  /**
   * 楼盘管理
   */
  // 列表查询
  public COMMUNITY_LIST = `${this.HOST}/community/getCommunityList`;
  public ADD_COMMUNITY = `${this.HOST}/community/add`;
  public EDIT_COMMUNITY = `${this.HOST}/community/edit`;
  public DELETE_COMMUNITY = `${this.HOST}/community/delete`;
  public EXCHANGE_INFO_COMMUNITY = `${this.HOST}/community/exchangeInfo`;
  public DOWN_HOUSE_STATUS_COMMUNITY = `${this.HOST}/community/downHouseStatus`;
  public DOWN_HOUSE_EXCHANGE_COMMUNITY = `${this.HOST}/community/downloadHouseExchange`;
  // 选择器列表
  public SELECT_COMMUNITY = `${this.HOST}/community/select`;
  public COMPANYID_BY_COMMUNITY = `${this.HOST}/community/getCompanyIdByCommunity`;
  public AMOUNT_INFO_BY_COMMUNITY = `${this.HOST}/community/amountInfo`;
  public DUMP_INFO_BY_COMMUNITY = `${this.HOST}/dump/list`;

  /**
   * 开发商管理
   */
  // 查看开发商列表
  public GET_DEVELOPER_LIST = `${this.HOST}/developer/getDeveloperList`;

  /**
   * 物业公司管理
   */
  // 查看物业公司管理列表
  public GET_PROPERTY_LIST = `${this.HOST}/property/getPropertyList`;
  // 新增物业公司
  public ADD_PROPERTY = `${this.HOST}/property/add`;
  // 编辑物业公司
  public EDIT_PROPERTY = `${this.HOST}/property/edit`;
  // 查看物业
  public VIEW_PROPERTY = `${this.HOST}/property/view`;
  // 禁用物业公司
  public DISABLE_PROPERTY = `${this.HOST}/property/disable`;

  /**
   * 巡查
   */
  public PATROL_PLAN_LIST = `${this.HOST}/patrol/plan/list`;
  public ADD_PATROL_PLAN = `${this.HOST}/patrol/plan/add`;
  public EDIT_PATROL_PLAN = `${this.HOST}/patrol/plan/edit`;
  public VIEW_PATROL_PLAN = (id: any) => `${this.HOST}/patrol/plan/info/${id}`;

  // 任务执行结果状态列表
  public TASK_PATROL_STATUS_LIST = `${this.HOST}/patrol/task/taskPatrolStatusList`;
  // 巡更任务状态列表
  public TASK_STATUS_LIST = `${this.HOST}/patrol/task/taskStatusList`;
  // 巡更记录状态列表
  public RECORD_STATUS_LIST = `${this.HOST}/patrol/record/recordStatusList`;
  // 巡查记录列表
  public PATROL_RECORD_LIST = `${this.HOST}/patrol/record/list`;
  // 巡查记录详情
  public PATROL_RECORD_DETAIL = (taskId: any) => `${this.HOST}/patrol/task/patrolRecord/${taskId}`;
  // 巡查任务列表
  public LIST_TASK = `${this.HOST}/patrol/task/list`;
  public ADD_TASK = `${this.HOST}/patrol/task/add`;
  public PATROL_USERS = `${this.HOST}/admin/user/patrolUserList`;
  // 批量指派任务
  public PATROL_TASK_BATCH_DISPATCH = `${this.HOST}/patrol/task/batchDispatch`;
  // 批量指派任务
  public PATROL_TASK_DISPATCH = `${this.HOST}/patrol/task/dispatch`;

  // 巡查任务执行状态
  public PATROL_TASK_STATUS_LIST = `${this.HOST}/patrol/task/patrolTaskStatusList`;
  // 根据时间查巡查次数
  public PATROL_COUNT = `${this.HOST}/patrol/patrolCountByDuration`;

  /**
   * 房源库
   */
  // 房源矩阵
  public BUILDING_HOUSE_MATRIX = `${this.HOST}/house/buildingHouseMatrix`;
  // 楼盘房源树
  public HOUSE_TREE_BY_COMMUNITY = `${this.HOST}/house/HouseTreeByCommunity`;
  // 行政区楼盘树
  public COMMUNITY_TREE = `${this.HOST}/community/tree`;
  public COMMUNITY_TREE_HOUSE = `${this.HOST}/community/treeByHouse`;
  // 查看楼盘详情
  public COMMUNITY_VIEW = `${this.HOST}/community/view`;
  // 查看楼盘房源统计信息
  public HOUSE_STAT_INFO = `${this.HOST}/community/houseStatInfo`;
  // 查看楼盘证件信息信息
  public CERTIFICATE = `${this.HOST}/community/certificate`;
  // 查看楼盘调配信息
  public ALLOC_INFO = `${this.HOST}/community/allocInfo`;
  // 房源面积统计
  public HOUSE_AREA_STATISTICS = `${this.HOST}/house/houseAreaStatistics`;
  // 房源数量统计
  public HOUSE_COUNT_STATISTICS = `${this.HOST}/house/houseCountStatistics`;
  // 房源金额统计
  public HOUSE_VALUE_STATISTICS = `${this.HOST}/house/houseValueStatistics`;
  // 房源流转记录
  public HOUSE_FLOW_RECORD = `${this.HOST}/house/houseFlowRecord`;
  // 删除房源流转记录
  public DELETE_EXCHANGE_RECORD = `${this.HOST}/house/deleteHouseExchange`;
  // 房源资金列表
  public HOUSE_FUND_LIST = `${this.HOST}/house/houseFundList`;
  // 房源资金统计
  public HOUSE_FUND_STATISTICS = `${this.HOST}/house/houseFundStatistics`;

  // 房源资金情况
  public HOUSE_FLOW_MONEY = `${this.HOST}/house/houseMoney`;
  // 编辑房源
  public UPDATE_HOUSE = `${this.HOST}/house/updateHouse`;
  // 编辑房源
  public DELETE_HOUSE = `${this.HOST}/house/deleteHouse`;
  // 修改房源冻结状态
  public CHANGE_FREEZE_STATUS = `${this.HOST}/house/changeFreezeStatus`;

  /**
   * 冻结和解冻
   */
  public FREEZE_LIST_OF_FINISH = `${this.HOST}/freeze/listOfFinish`;
  public FREEZE_LIST_OF_IN_APPROVAL = `${this.HOST}/freeze/listOfInApproval`;
  public FREEZE_LIST_OF_CLOSE = `${this.HOST}/freeze/listOfClose`;
  public ADD_FREEZE_DRAFT = `${this.HOST}/freeze/draft`;
  public ADD_FREEZE_APPLY = `${this.HOST}/freeze/apply`;
  public ADD_UNFREEZE_DRAFT = `${this.HOST}/unFreeze/draft`;
  public ADD_UNFREEZE_APPLY = `${this.HOST}/unFreeze/apply`;
  public FREEZE_INFO = (id: any) => `${this.HOST}/freeze/info/${id}`;
  public UNFREEZE_INFO = (id: any) => `${this.HOST}/unFreeze/info/${id}`;

  /**
   * 首页
   */
  // 首页项目管理
  public PROJECT_MANAGER_OF_INDEX = `${this.HOST}/project/projectManagerOfIndex`;
  // 首页楼盘数量
  public COMMUNITY_COUNT_OF_INDEX = `${this.HOST}/community/communityCountOfIndex`;
  // 首页楼盘管理
  public COMMUNITY_MANAGER_OF_INDEX = `${this.HOST}/community/communityManagerOfIndex`;
  // 首页楼盘管理-土地部门
  public COMMUNITY_MANAGER_OF_INDEX_TD = `${this.HOST}/community/communityManagerOfIndexTD`;
  // 首页总房源占比分析统计
  public HOUSE_COUNT_BY_IDENTITY = `${this.HOST}/house/houseCountByIdentity`;
  // 首页总房源占比分析统计-土地部门
  public HOUSE_COUNT_BY_IDENTITY_TD = `${this.HOST}/house/houseCountByIdentityTD`;
  // 首页统计进行中的项目
  public PROGRESS_PROJECT_COUNT = `${this.HOST}/project/progressProjectCount`;
  // 首页本月房源调配、去化、调剂统计
  public HOUSE_COUNT_OF_TP_AND_QH_AND_TJ = `${this.HOST}/house/houseCountOfTpAndQhAndTj`;
  // 首页分公司主体房源占比分析统计
  public HOUSE_COUNT_BY_COMPANY_ID = (companyId: any) => `${this.HOST}/house/houseCountByCompanyId/${companyId}`;
  // 首页分公司主体房源占比分析统计-土地部门
  public HOUSE_COUNT_BY_COMPANY_ID_TD = (companyId: any) => `${this.HOST}/house/houseCountByCompanyIdTD/${companyId}`;
  // 首页房源流转统计
  public GET_HOUSE_CHANGE_STAT = `${this.HOST}/apply/getHouseChangeStat`;
  // 首页我的申请/我的申请->申请列表
  public GET_MY_APPLY = `${this.HOST}/apply/getMyApply`;
  // 首页资金统计
  public FUND_COUNT = `${this.HOST}/fund/fundCount`;
  // 首页资金统计-土地部门
  public FUND_COUNT_TD = `${this.HOST}/fund/fundCountTD`;
  // 首页资金统计折线图
  public FUND_COUNT_OF_GRAPH = `${this.HOST}/fund/fundCountOfGraph`;
  // 首页资金统计折线图-土地部门
  public FUND_COUNT_OF_GRAPH_TD = `${this.HOST}/fund/fundCountOfGraphTD`;

  /**
   * 工作台
   */
  // 全部申请--列表
  public APPLY_ALL_LIST = `${this.HOST}/apply/getAllApply`;
  // 全部申请--统计
  public APPLY_ALL_COUNT = `${this.HOST}/apply/getApplyCountByIdentity`;
  // 我的申请--列表
  public APPLY_ALL_MY_LIST = `${this.HOST}/apply/getMyApply`;
  // 我的申请--统计
  public APPLY_ALL_MY_COUNT = `${this.HOST}/apply/getMyApplyCount`;
  /**
   * 草稿箱 --删除
   */
  public DEL_DEAFT = `${this.HOST}/apply/del`;
  public WITHDRAW_NEW = `${this.HOST}/apply/withdraw-new`;
  public DEL_PROPERTY_FEE_DEAFT = `${this.HOST}/fund/deletePropertyFeeDraft`;
  public DEL_PATROL_FEE_DEAFT = `${this.HOST}/fund/deletePatrolFeeDraft`;
  public DEL_PAY_APPLY_DEAFT = `${this.HOST}/fund/deletePayApplyDraft`;
  public DEL_FUND_RETRUN_DEAFT = `${this.HOST}/fund/deleteFundReturnDraft`;
  /**
   * 资金
   */
  // 支付申请列表
  public PAY_LIST = `${this.HOST}/fund/payList`;
  // 回款申请列表
  public FUND_RETURN_LIST = `${this.HOST}/fund/fundReturnList`;
  // 新增支付申请
  public PAY_ADD = `${this.HOST}/fund/addPayApply`;
  // 编辑支付申请
  public PAY_EDIT = `${this.HOST}/fund/editPayApply`;
  // 新增历史支付申请
  public PAY_HISTORY_ADD = `${this.HOST}/fund/addHistoryPayApply`;
  // 编辑历史支付申请
  public PAY_HISTORY_EDIT = `${this.HOST}/fund/editHistoryPayApply`;
  // 审核历史支付申请
  public PAY_HISTORY_CHECK = `${this.HOST}/fund/checkHistoryPayApply`;

  // 支付申请详情
  public VIEW_PAY_APPLY = `${this.HOST}/fund/viewPayApply`;

  // 支付-历史-审核
  public CHECK_PAY_APPLY = `${this.HOST}/fund/checkPayHistory`;
  // 支付详情的房源列表
  public APPLY_INFO_HOUSES = `${this.HOST}/fund/getHouseListByPayApply`;
  // 回款详情的房源列表
  public FUND_RETURN_APPLY_INFO_HOUSES = `${this.HOST}/fund/getHouseListByReturnFundApply`;
  // 回款-审核
  public CHECK_RETURN_APPLY = `${this.HOST}/fund/checkReturn`;
  // 回款-历史-审核
  public CHECK_RETURN_APPLY_HISTORY = `${this.HOST}/fund/checkReturnHistory`;
  // 新增回款申请
  public RETURN_ADD = `${this.HOST}/fund/fundReturnApply`;
  // 编辑回款申请
  public FUND_RETURN_EDIT = `${this.HOST}/fund/fundReturnEdit`;
  // 回款申请详情
  public VIEW_RETURN_APPLY = `${this.HOST}/fund/viewFundReturnApply`;
  // 资金大写转换
  public FUND_TRANSITION = `${this.HOST}/fund/fundTransition`;
  // 回款申请通过
  public FUND_RETURN_PASSED = `${this.HOST}/fund/fundReturnPassed`;
  // 空置物业费列表
  public PROPERTY_FEE_LIST = `${this.HOST}/fund/propertyFeeList`;
  // 新增空置物业费申请
  public PROPERTY_FEE_APPLY_ADD = `${this.HOST}/fund/addPropertyFeeApply`;
  // 编辑空置物业费申请
  public PROPERTY_FEE_APPLY_EDIT = `${this.HOST}/fund/editPropertyFeeApply`;
  // 空置物业费计算
  public COMPUTE_PROPERTY_FEE_PAY = `${this.HOST}/fund/computePropertyFeePay`;
  // 查看空置物业费申请
  public PROPERTY_FEE_APPLY_VIEW = `${this.HOST}/fund/viewPropertyFeeApply`;
  // 物业费-历史-审核
  public CHECK_PROPERTY_APPLY = `${this.HOST}/fund/checkPropertyHistory`;
  // 巡查费列表
  public PATROL_FEE_LIST = `${this.HOST}/fund/patrolFeeList`;
  // 新增巡查费申请
  public PATROL_FEE_APPLY_ADD = `${this.HOST}/fund/addPatrolFeeApply`;
  // 编辑巡查费申请
  public PATROL_FEE_APPLY_EDIT = `${this.HOST}/fund/editPatrolFeeApply`;
  // 查看巡查费申请
  public PATROL_FEE_APPLY_VIEW = `${this.HOST}/fund/viewPatrolFeeApply`;
  // 房源款支付统计
  public HOUSE_PAY_STAT = `${this.HOST}/fund/housePayStat`;
  // 房源款回款统计
  public MONEY_RETURN_STAT = `${this.HOST}/fund/moneyReturnStat`;
  // 空置物业费统计
  public PROPERTY_FEE_STAT = `${this.HOST}/fund/propertyFeeStat`;
  // 巡查费统计
  public PATROL_FEE_STAT = `${this.HOST}/fund/patrolFeeStat`;
  // 财务成本测算列表
  public FUND_FINANCIAL_COST_LIST = `${this.HOST}/fund/financialCostList`;
  // 按楼盘统计房源流转
  public FUND_House_Exchange_Community_LIST = `${this.HOST}/fund/houseExchangeByCommunity`;
  // 导出房源明细表
  public EXPORT_HOUSE_DETAIL = `${this.HOST}/fund/downloadHouseDetail`;
  // 下载房源统计
  public EXPORT_HOUSE_STAT = `${this.HOST}/fund/downloadHouseStat`;
  /**
   * 合同
   */
  // 合同列表
  public CONTRACT_LIST = `${this.HOST}/contract/list`;
  public CONTRACT_NODE_LIST = `${this.HOST}/contract/nodeList`;
  public CONTRACT_CERT_LIST = `${this.HOST}/contract/certList`;
  public HOUSE_LIST_BY_CONTRACT = `${this.HOST}/contract/houseList`;
  public LIST_CONTRACT = `${this.HOST}/contract/contractList`;
  public CONTRACT_FUND_STAT = `${this.HOST}/contract/fundStat`;
  public CONTRACT_HOUSE_STAT = `${this.HOST}/contract/houseStat`;
  public VIEW_CONTRACT = `${this.HOST}/contract/view`;
  public CONTRACT_FUND_RECORD = `${this.HOST}/contract/fundRecord`;
  public CONTRACT_HOUSE_LIST = `${this.HOST}/contract/houseListByContract`;
  public RELATED_CONTRACT_LIST = `${this.HOST}/contract/getRelatedContract`;
  public CONTRACT_EDIT = `${this.HOST}/contract/edit`;

  /**
   * 房源备案
   */
  // 添加备案
  public HOUSE_RECORD = `${this.HOST}/house/record`;
  // 获取房源备案详情
  public GET_RECORD_INFO = (houseId: any) => `${this.HOST}/house/getRecordInfo/${houseId}`;
  // 修改房源备案
  public EDIT_RECORD = `${this.HOST}/house/editRecord`;
  // 备案/未备案楼盘列表
  public RECORD_HOUSE_LIST = `${this.HOST}/house/recordHouseList`;
  // 删除备案
  public DELETE_RECORD = (houseId: any) => `${this.HOST}/house/deleteRecord/${houseId}`;

  /**
   * 分配使用部门
   */
  // 根据主体查询可更改的使用部门
  public LIST_BY_COMPANY_ID_AND_TAG = `${this.HOST}/admin/domain/listByCompanyIdAndTag`;
  // 单个操作使用部门
  public TO_USE_DEPT_SINGLE = `${this.HOST}/house/toUseDeptSingle`;
  // 批量分配使用部门
  public TO_USE_DEPT_BATCH = `${this.HOST}/house/toUseDeptBatch`;
  // 分配使用部门列表
  public TO_USE_DEPT_LIST = `${this.HOST}/house/toUseDeptList`;
  // 收回权限
  public TO_USE_DEPT_BACK = `${this.HOST}/house/toUseDeptBack`;

  /**
   * 可视化接口
   */
  // 房源统计
  public VISUAL_HOUSE_STAT = `${this.HOST}/visual/houseStat`;
  // 房源流转统计
  public VISUAL_HOUSE_EXCHANGE_STAT = `${this.HOST}/visual/houseExchangeStat`;
  // 项目统计
  public VISUAL_PROJECT_STAT = `${this.HOST}/visual/projectStat`;
  // 资金统计
  public VISUAL_FUND_STAT = `${this.HOST}/visual/fundStat`;
  // 房源红黑榜
  public VISUAL_HOUSE_LIST_COUNT = `${this.HOST}/visual/houseList`;
  // 地图楼盘房源弹窗
  public VISUAL_COMMUNITY_HOUSE_COUNT = `${this.HOST}/visual/communityHouseCount`;
  // 地图项目房源弹窗
  public VISUAL_PROJECT_HOUSE_COUNT = `${this.HOST}/visual/projectHouseCount`;
  // 所有项目
  public VISUAL_PROJECT_LIST = `${this.HOST}/visual/projectList`;
  // 所有楼盘
  public VISUAL_COMMUNITY_LIST = `${this.HOST}/visual/communityList`;
  // 房源列表弹窗
  public VISUAL_HOUSE_LIST_POP = `${this.HOST}/visual/houseListPop`;
  // 异常房源统计
  public EXCEPTION_HOUSE_STAT = `${this.HOST}/visual/exceptionHouseStat`;
  // 异常房源列表
  public EXCEPTION_HOUSE_LIST = `${this.HOST}/visual/exceptionHouseList`;

  /**
   * 巡查相关接口
   */
  public PATROL_LEADER_LIST = `${this.HOST}/patrol/getPatrolLeaderList`;

  public PATROL_ADD_USER = `${this.HOST}/patrol/addPatrolUser`;

  public PATROL_USER_LIST = `${this.HOST}/patrol/patrolUserList`;

  public PATROL_USER_EDIT = `${this.HOST}/patrol/editPatrolUser`;

  public PATROL_USER_RESET_PASSWORD = (id: any) => `${this.HOST}/admin/user/initPwd/${id}`;

  public PATROL_USER_UNBIND_WX = (id: any) => `${this.HOST}/admin/user/unBindWechat/${id}`;

  public PATROL_USER_DISABLED = (id: any) => `${this.HOST}/admin/user/disabled/${id}`;

  public PATROL_USER_ENABLED = (id: any) => `${this.HOST}/admin/user/enable/${id}`;

  public PATROL_CAN_HOUSE_COMMUNITY = `${this.HOST}/patrol/community/canPatrolHouseList`;

  public PATROL_ADD_HOUSE_COMMUNITY = `${this.HOST}/patrol/community/saveCommPatrolHosue`;

  public PATROL_COMMUNITY_HOUSE_STAT_COUNT = `${this.HOST}/patrol/community/statistics`;

  public PATROL_COMMUNITY_HOUSE_LIST = `${this.HOST}/patrol/community/list`;

  public PATROL_ADD_TASK = `${this.HOST}/patrol/task/add`;

  public PATROL_ADD_BATCH_TASK = `${this.HOST}/patrol/task/addBatch`;

  public PATROL_TASK_LIST_MANAGER = `${this.HOST}/patrol/task/listByManager`;

  public PATROL_TASK_LIST_LEADER = `${this.HOST}/patrol/task/listByTaskLeader`;

  public PATROL_TASK_DETAIL = `${this.HOST}/patrol/task/taskInfo`;

  public PATROL_TASK_HOUSE_LIST = `${this.HOST}/patrol/task/taskRecordList`;

  public PATROL_TASK_CHECK_LIST = `${this.HOST}/patrol/task/spotCheckTaskList`;

  public PATROL_TASK_RECORD_DETAIL = `${this.HOST}/patrol/task/taskRecordInfo`;

  public PATROL_COMMUNITY_DETAIL = `${this.HOST}/patrol/community/communityInfoStatistics`;

  public PATROL_COMMUNITY_COMM_HOUSE_LIST = `${this.HOST}/patrol/community/commPatrolHosueList`;

  public PATROL_COMMUNITY_RECORD_LIST = `${this.HOST}/patrol/community/recordListByPatrol`;

  public PATROL_COMMUNITY_CHECK_LIST = `${this.HOST}/patrol/community/recordListBySpotCheck`;

  public PATROL_CHECK_DETAIL = `${this.HOST}/patrol/task/spotCheckTaskInfo`;

  public PATROL_CHECK_HOUSE_LIST = `${this.HOST}/patrol/task/spotCheckRecordList`;

  public PATROL_CHECK_HOUSE_DETAIL = `${this.HOST}/patrol/task/spotCheckRecordInfo`;

  public PATROL_HOUSE_RECORD_LIST = `${this.HOST}/patrol/task/patrol/history`;

  public PATROL_TASK_BATCH_TO = `${this.HOST}/patrol/task/dispatch/batch`;

  public PATROL_TASK_TO = `${this.HOST}/patrol/task/dispatch/single`;

  public PATROL_TASK_RECORD_CONFIRM = `${this.HOST}/patrol/task/record/verifySingle`;

  public PATROL_BATCH_CONFIRM = `${this.HOST}/patrol/task/record/verifybatch`;

  public PATROL_TASK_RECORD_UPDATE_INFO = `${this.HOST}/patrol/task/record/verifyUpdate`;

  public PATROL_TASK_VERIFY_SUBMIT = `${this.HOST}/patrol/task/verifySubmit`;

  public PATROL_TASK_UN_VERIFY_RECORD_COUNT = `${this.HOST}/patrol/task/record/unVerifyRecordCount`;

  public PATROL_RECORD_LIST_BY_HOUSE = `${this.HOST}/patrol/patrolRecordListByHouse`;
  // 生成巡查月报表
  public CREATE_PATROL_REPORT = `${this.HOST}/report/createPatrolReport`;
  // 编辑巡查月报表
  public EDIT_PATROL_REPORT = `${this.HOST}/report/editPatrolReport`;
  // 导出巡查月报表
  public EXPORT_PATROL_REPORT = `${this.HOST}/report/exportPatrolRptInfo`;
  // 巡查月报表详情
  public GET_PATROL_RPT_INFO = `${this.HOST}/report/getPatrolRptInfo`;
  // 巡查月报表列表
  public GET_PATROL_RPT_LIST = `${this.HOST}/report/getPatrolRptList`;
  // 生成抽查报表
  public CREATE_SPOT_CHECK_REPORT = `${this.HOST}/report/createSpotCheckReport`;
  // 删除抽查报表
  public DELETE_SPOT_CHECK_RPT = `${this.HOST}/report/deleteSpotCheckRpt`;
  // 编辑抽查报表
  public EDIT_SPOT_CHECK_REPORT = `${this.HOST}/report/editSpotCheckReport`;
  // 导出抽查报表
  public EXPORT_SPOT_CHECK_RPT_INFO = `${this.HOST}/report/exportSpotCheckRptInfo`;

  // 导出抽查报表明细
  public EXPORT_SPOT_CHECK_RPT_INFO_DETAIL = (id: any) => `${this.HOST}/report/exportSpotCheckRptDetails/${id}`;
  // 抽查报表详情
  public GET_SPOT_CHECK_RPT_INFO = `${this.HOST}/report/getSpotCheckRptInfo`;
  // 抽查报表列表
  public GET_SPOT_CHECK_RPT_LIST = `${this.HOST}/report/getSpotCheckRptList`;
  // 有巡查记录的楼盘列表
  public GET_COMMUNITY_BY_SPOT_CHECK = `${this.HOST}/community/getCommunitysBySpotCheck`;
  // 删除未巡查的巡查任务
  public DELETE_PATROL_TASK = `${this.HOST}/patrol/deletePatrolTask`;

  public EXPORT_SPOT_CHECK_RPT_HOUSE_LIST = `${this.HOST}/patrol/task/spotCheckRecordList/export`;

  /***
   * 查询合同编号
   */
  public CONTRACTNO_LIST = `${this.HOST}/fund/queryContractNo`;
  /***
   * 查询合同名称和附件
   */
  public CONTRACTNO_LIST_INFO = `${this.HOST}/fund/queryContractInfo`;

  /***
   * 导入时查询房源信息
   */
  public HOUSE_LIST_BY_LIST = `${this.HOST}/house/selectHouseListByList`;
  /***
   * 导入时查询房源信息---房源的基本信息都有--不将房源价格等返回
   */
  public HOUSE_LIST_INFO_BY_LIST = `${this.HOST}/house/selectHouseListInfoByList`;

  /***
   * 导入时查询房源信息---资金模块
   */
  public HOUSE_LIST_BY_MONEY = `${this.HOST}/house/selectHouseListByMoney`;

  public SELECT_HOUSE = `${this.HOST}/hseHistory/selectHouse`;
  /***
   * 查询日志
   */
  public LIST_LOG = `${this.HOST}/admin/log/list`;
  /****
   添加融资信息
   */
  public ADD_DUMP = `${this.HOST}/dump/add`;
  /****
   查看融资信息
   */
  public VIEW_DUMP = `${this.HOST}/dump/view`;

  /****
   添加锁定解锁房源
   */
  public ADD_LOCK = `${this.HOST}/lock/add`;
  /****
   查询锁定房源
   */
  public QUERY_LOCK = `${this.HOST}/lock/listLock`;
  /****
   批量修改房源状态
   */
  public ADD_BATCH_HOUSE_STATUS = `${this.HOST}/batch-house/add`;
  /****
   批量修改房源信息
   */
  public ADD_BATCH_HOUSE_INFO = `${this.HOST}/batch-house/updateHouseInfoByList`;

  /***
   * 查询巡查房源修改日志
   */
  public PATROL_DISTRICT_LOG_LIST = `${this.HOST}/patrol/district/list`;

  /****
   查看巡查房源修改房源
   */
  public VIEW_PATROL_DISTRICT_LOG = `${this.HOST}/patrol/district/view`;

  // 房源列表根据房源id查
  public HOUSE_REMARK_LOG_LIST_BY_ID = `${this.HOST}/house/remark-log/list`;
}
