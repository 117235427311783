import { Injectable } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { Observable } from 'rxjs';
import { API } from './api';
import { ShowyePageApiPath, ShowyePageApiService, ShowyePageUtilService } from '@showye/showye-page-base';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * 接口服务，专用从服务器请求数据。缓存管理也在这里进行。
 */
export class NbApiService extends ShowyePageApiService {
  constructor(protected baseApi: ShowyePageApiPath, private api: API, protected http: _HttpClient, protected coder: ShowyePageUtilService) {
    super(baseApi, http, coder);
  }

  // 获取全部机构树
  public viewCompanyTree(): Observable<any> {
    return this.listDomains().pipe(
      map((e: any) => {
        const smap: any = {};
        const rowMap: any = {};
        e.data
          .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
          .forEach((x: any) => {
            smap[x.id] = x;
            rowMap[x.id] = x;
            x.key = x.id;
            x.title = x.name + (x.code && x.code.length > 0 ? '[' + x.code + ']' : '');
            x.children = [];
            x.expanded = true;
            x.isLeaf = false;
            x.expand = true;
            e.data.forEach((y: any) => {
              if (x.parentId && y.id === x.parentId) {
                x.parent = y;
              }
            });
          });
        e.data.forEach((x: any) => {
          if (!!smap[x.parentId]) {
            smap[x.parentId].children.push(x);
          }
        });
        e.data.forEach((x: any) => {
          if (!x.children || x.children.length <= 0) {
            x.isLeaf = true;
          }
        });
        e.data = {
          domains: Object.keys(smap)
            .filter((k) => !smap[k].parentId)
            .map((k) => smap[k]),
          map: smap,
          domainEnums: rowMap,
        };
        return e;
      }),
    );
  }

  // 枚举字典相关
  public enumList(par: any): Observable<any> {
    return this.http.post(this.api.ENUM_LIST, par);
  }

  // 获取公司主体列表
  public getCompanyList(): Observable<any> {
    return this.http.get(this.api.GET_COMPANY_LIST);
  }

  // 获取公司下的部门信息
  public listByCompanyId(id: any): Observable<any> {
    return this.http.get(this.api.LIST_BY_COMPANY_ID(id));
  }

  // 获取个人主体信息
  public getMyCompany(): Observable<any> {
    return this.http.get(this.api.GET_MY_COMPANY);
  }

  // 根据部门获取员工信息
  public getUserListByDomainId(id: any): Observable<any> {
    return this.http.get(this.api.GET_USER_LIST_BY_DOMAIN_ID(id));
  }

  // 根据角色获取员工信息
  public getUserListByRoleId(id: any): Observable<any> {
    return this.http.get(this.api.GET_USER_LIST_BY_ROLE_ID(id));
  }

  // 根据主体和用户id查询所属部门
  public getDomainIdByCompanyAndUserId(par: any): Observable<any> {
    return this.http.post(this.api.GET_DOMAIN_ID_BY_COMPANY_AND_USER_ID, par);
  }

  // 授权登录
  public loginByToken(token: any): Observable<any> {
    return this.http.get(this.api.LOGIN_BY_TOKEN(token));
  }

  // 查看直属下级机构列表
  public listChildrenByDomain(id: any): Observable<any> {
    return this.http.get(this.api.LIST_CHILDREN_BY_DOMAIN(id));
  }

  // 查看部门内部员工
  public listStaffsByDomain(id: any): Observable<any> {
    return this.http.get(this.api.LIST_STAFF_BY_DOMAIN(id));
  }

  // 权限组列表
  public authGroupList(): Observable<any> {
    return this.http.get(this.api.AUTH_GROUP_LIST);
  }

  // 权限组新增
  public authGroupAdd(par: any): Observable<any> {
    return this.http.post(this.api.AUTH_GROUP_ADD, par);
  }

  // 权限组编辑
  public authGroupEdit(par: any): Observable<any> {
    return this.http.post(this.api.AUTH_GROUP_EDIT, par);
  }

  // 权限组删除
  public authGroupDelete(id: any): Observable<any> {
    return this.http.delete(this.api.AUTH_GROUP_DELETE(id));
  }

  // 用户列表下拉选择
  public getUserList(par: any): Observable<any> {
    return this.http.get(this.api.GET_USER_LIST, par);
  }

  // 根据主体选择该主体下面的人员下拉选择
  public getUserListByCompanyId(par: any): Observable<any> {
    return this.http.post(this.api.GET_USER_LIST_BY_COMPANYID, par);
  }

  // 根据主体和部门用户列表下拉选择
  public getUserListByParam(par: any): Observable<any> {
    return this.http.post(this.api.GET_USER_LIST, par);
  }

  // 用户列表
  public userList(par: any): Observable<any> {
    return this.http.post(this.api.USER_LIST, par);
  }

  // 新增用户
  public addUser(par: any): Observable<any> {
    return this.http.post(this.api.ADD_USER, par);
  }

  // 编辑用户
  public editUser(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_USER, par);
  }

  public editAvatar(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_AVATAR, par);
  }

  // 删除用户
  public deleteUser(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_USER(id));
  }

  public initPwd(id: any): Observable<any> {
    return this.http.get(this.api.INIT_PWD(id));
  }

  // 查看用户详情
  public userInfo(id: any): Observable<any> {
    return this.http.get(this.api.USER_INFO(id));
  }

  // 岗位查询员工
  public getStaffByPositionId(positionId: any): Observable<any> {
    return this.http.get(this.api.BY_POSITION_ID(positionId));
  }

  // 职能查询员工
  public getStaffByRoleId(roleId: any): Observable<any> {
    return this.http.get(this.api.BY_ROLE_ID(roleId));
  }

  // 修改密码
  public changeMyPassword(param: any): Observable<any> {
    return this.http.post(this.api.MY_CHANGE_PASSWORD, param);
  }

  // 修改个人信息
  public updateMyInfo(param: any): Observable<any> {
    return this.http.post(this.api.MY_UPDATE_INFO, param);
  }

  public myButton(): Observable<any> {
    return this.http.get(this.api.MY_BUTTONS);
  }

  /**
   * 楼盘管理
   */
  public communityTree(param: any): Observable<any> {
    return this.http.post(this.api.COMMUNITY_TREE, param);
  }

  public communityTreeByHouse(param: any): Observable<any> {
    return this.http.post(this.api.COMMUNITY_TREE_HOUSE, param);
  }

  public communityView(param: any): Observable<any> {
    return this.http.post(this.api.COMMUNITY_VIEW, param);
  }

  public houseStatInfo(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_STAT_INFO, param);
  }

  public certificate(param: any): Observable<any> {
    return this.http.post(this.api.CERTIFICATE, param);
  }

  public allocInfo(param: any): Observable<any> {
    return this.http.post(this.api.ALLOC_INFO, param);
  }

  public houseAreaStatistics(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_AREA_STATISTICS, param);
  }

  public houseCountStatistics(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_COUNT_STATISTICS, param);
  }

  public houseValueStatistics(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_VALUE_STATISTICS, param);
  }

  public communityList(param: any): Observable<any> {
    return this.http.post(this.api.COMMUNITY_LIST, param);
  }

  public addCommunity(param: any): Observable<any> {
    return this.http.post(this.api.ADD_COMMUNITY, param);
  }

  public editCommunity(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_COMMUNITY, param);
  }

  public deleteCommunity(param: any): Observable<any> {
    return this.http.post(this.api.DELETE_COMMUNITY, param);
  }

  public getCommunitySelectList(): Observable<any> {
    return this.http.post(this.api.SELECT_COMMUNITY);
  }

  public getCompanyIdByCommunity(param: any): Observable<any> {
    return this.http.post(this.api.COMPANYID_BY_COMMUNITY, param);
  }

  public getAmountInfo(param: any): Observable<any> {
    return this.http.post(this.api.AMOUNT_INFO_BY_COMMUNITY, param);
  }

  public getDumpInfo(param: any): Observable<any> {
    return this.http.post(this.api.DUMP_INFO_BY_COMMUNITY, param);
  }

  public getExchangeInfo(param: any): Observable<any> {
    return this.http.post(this.api.EXCHANGE_INFO_COMMUNITY, param);
  }

  public downHouseStatusCommunity(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_HOUSE_STATUS_COMMUNITY, param);
  }

  public downHouseExchangeCommunity(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_HOUSE_EXCHANGE_COMMUNITY, param);
  }

  /**
   * 开发商管理
   */
  public getDeveloperList(param: any): Observable<any> {
    return this.http.post(this.api.GET_DEVELOPER_LIST, param);
  }

  /**
   * 物业公司管理
   */
  public getPropertyList(param: any): Observable<any> {
    return this.http.post(this.api.GET_PROPERTY_LIST, param);
  }

  public addProperty(param: any): Observable<any> {
    return this.http.post(this.api.ADD_PROPERTY, param);
  }

  public editProperty(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_PROPERTY, param);
  }

  public viewProperty(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_PROPERTY, param);
  }

  public disableProperty(param: any): Observable<any> {
    return this.http.post(this.api.DISABLE_PROPERTY, param);
  }

  /**
   * 行政区树
   */
  public districtTree(param: any): Observable<any> {
    return this.http.post(this.api.DISTRICT_TREE, param);
  }

  /**
   * 项目管理
   */
  public listProject(param: any): Observable<any> {
    return this.http.post(this.api.LIST_PROJECT, param);
  }

  public addProject(param: any): Observable<any> {
    return this.http.post(this.api.ADD_PROJECT, param);
  }

  public editProject(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_PROJECT, param);
  }

  public delProject(param: any): Observable<any> {
    return this.http.post(this.api.DEL_PROJECT, param);
  }

  public infoProject(param: any): Observable<any> {
    return this.http.post(this.api.INFO_PROJECT, param);
  }

  public statSingleProject(param: any): Observable<any> {
    return this.http.post(this.api.STAT_SINGLE_PROJECT, param);
  }

  public statAllProject(param: any): Observable<any> {
    return this.http.post(this.api.STAT_ALL_PROJECT, param);
  }

  public changeStatus(param: any): Observable<any> {
    return this.http.post(this.api.CHANGE_STATUS, param);
  }

  public getHouseStatistics(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_STATISTICS, param);
  }

  public flowRecordByProject(param: any): Observable<any> {
    return this.http.post(this.api.FLOW_RECORD_BY_PROJECT, param);
  }

  public houseIdsByProject(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_IDS_BY_PROJECT, param);
  }

  public getDeliverListByProject(par: any): Observable<any> {
    return this.http.post(this.api.DELIVER_LIST_BY_PROJECT, par);
  }

  public updateProgress(par: any): Observable<any> {
    return this.http.post(this.api.UPDATE_PROJECT_PROGRESS, par);
  }

  public allocInfoByProject(param: any): Observable<any> {
    return this.http.post(this.api.ALLOC_INFO_BY_PROJECT, param);
  }

  public ProjectHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.PROJECT_HOUSE_COUNT, param);
  }

  public DownProjectHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_PROJECT_HOUSE_COUNT, param);
  }

  public DeployHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.DEPLOY_HOUSE_COUNT, param);
  }

  public DownDeployHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_DEPLOYHOUSE_COUNT, param);
  }
  public CompanyProjectCount(param: any): Observable<any> {
    return this.http.post(this.api.COMPANY_PROJECT_COUNT, param);
  }
  public DownCompanyProjectCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_COMPANY_PROJECT, param);
  }
  public companyHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.COMPANY_HOUSE_COUNT, param);
  }

  public downCompanyHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_COMPANY_HOUSE_COUNT, param);
  }

  public companyCommunityCount(param: any): Observable<any> {
    return this.http.post(this.api.COMPANY_COMMUNITY_COUNT, param);
  }

  public downCompanyCommunityCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_COMPANY_COMMUNITY_COUNT, param);
  }

  public communityHouseInfoCount(param: any): Observable<any> {
    return this.http.post(this.api.COMMUNITY_HOUSE_INFO_COUNT, param);
  }

  public downCommunityHouseInfoCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_COMMUNITY_HOUSE_INFO_COUNT, param);
  }

  public OutCommunityCount(param: any): Observable<any> {
    return this.http.post(this.api.OUT_COMMUNITY_COUNT, param);
  }

  public DownOutCommunityCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_OUTCOMMUNITY_COUNT, param);
  }

  public CommunityHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.COMMUNITY_HOUSE_COUNT, param);
  }

  public DownCommunityHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_COMMUNITY_HOUSE_COUNT, param);
  }

  public HouseDeveloperTypeCount(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_DEVELOPER_TYPE_COUNT, param);
  }

  public DownHouseDeveloperTypeCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_HOUSE_DEVELOPER_TYPE_COUNT, param);
  }

  public houseMoneyCount(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_MONEY_COUNT, param);
  }

  public downHouseMoneyCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_HOUSE_MONEY_COUNT, param);
  }

  public taskErrCount(param: any): Observable<any> {
    return this.http.post(this.api.TASK_ERR_COUNT, param);
  }

  public downTaskErrCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_TASK_ERR_COUNT, param);
  }

  public HouseStockCount(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_STOCK_COUNT, param);
  }

  public DownHouseStockCount(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_HOUSE_STOCK_COUNT, param);
  }

  public companyHouseCountByYear(param: any): Observable<any> {
    return this.http.post(this.api.COMPANY_HOUSE_COUNT_BY_YEAR, param);
  }

  public downCompanyHouseCountByYear(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_COMPANY_HOUSE_COUNT_BY_YEAR, param);
  }

  public projectHouseCountByYear(param: any): Observable<any> {
    return this.http.post(this.api.PROJECT_HOUSE_COUNT_BY_YEAR, param);
  }

  public downProjectHouseCountByYear(param: any): Observable<any> {
    return this.http.post(this.api.DOWN_PROJECT_HOUSE_COUNT_BY_YEAR, param);
  }

  public getNodeNameListByProjectId(param: any): Observable<any> {
    return this.http.get(this.api.GET_NODE_NAME_LIST_BY_PROJECT_ID(param));
  }

  public nodeList(param: any): Observable<any> {
    return this.http.get(this.api.NODE_LIST(param));
  }

  public nodeAdd(param: any): Observable<any> {
    return this.http.post(this.api.NODE_ADD, param);
  }

  public nodeEdit(param: any): Observable<any> {
    return this.http.post(this.api.NODE_EDIT, param);
  }

  public nodeInfo(param: any): Observable<any> {
    return this.http.get(this.api.NODE_INFO(param));
  }

  public nodeDelete(param: any): Observable<any> {
    return this.http.delete(this.api.NODE_DELETE(param));
  }

  public impHouse(param: any): Observable<any> {
    return this.http.post(this.api.IMP_HOUSE, param);
  }

  public impHouseProject(param: any): Observable<any> {
    return this.http.post(this.api.IMP_HOUSE_PROJECT, param);
  }

  public impHousePrice(param: any): Observable<any> {
    return this.http.post(this.api.IMP_HOUSE_PRICE, param);
  }

  public impHouseSeason(param: any): Observable<any> {
    return this.http.post(this.api.IMP_HOUSE_SEASON, param);
  }

  public impUserOaId(param: any): Observable<any> {
    return this.http.post(this.api.IMP_USER_OAID, param);
  }

  public updateStaArea(param: any): Observable<any> {
    return this.http.post(this.api.UPD_HOUSE_AREA, param);
  }

  public updateNameToHouse(param: any): Observable<any> {
    return this.http.post(this.api.UPD_HOUSE_LEVYNAME, param);
  }

  public moneyReturned(param: any): Observable<any> {
    return this.http.post(this.api.MONEY_RETURNED, param);
  }

  public updateOAStatus(param: any): Observable<any> {
    return this.http.post(this.api.UPD_OA_STATUS, param);
  }

  public updateHouseExchange(param: any): Observable<any> {
    return this.http.post(this.api.UPD_HOUSE_EXCHANGE, param);
  }

  public updateApplyNo(param: any): Observable<any> {
    return this.http.post(this.api.UPD_APPLY_NO + '?applyNo=' + param);
  }

  /**
   * 房源订购
   */
  public enterAdd(param: any): Observable<any> {
    return this.http.post(this.api.ENTER_ADD, param);
  }

  public enterEdit(param: any): Observable<any> {
    return this.http.post(this.api.ENTER_EDIT, param);
  }

  public enterView(param: any): Observable<any> {
    return this.http.post(this.api.ENTER_VIEW, param);
  }

  public enterList(param: any): Observable<any> {
    return this.http.post(this.api.ENTER_LIST, param);
  }

  public changeContractDate(param: any): Observable<any> {
    return this.http.post(this.api.CHANGE_CONTRACT_DATE, param);
  }

  public rejectEnter(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_ENTER, param);
  }

  public passEnter(param: any): Observable<any> {
    return this.http.post(this.api.PASS_ENTER, param);
  }

  public downloadProjectList(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_PROJECT_LIST, param);
  }
  public downloadProjectHouseCompanyId(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_PROJECT_HOUSE_BY_COMPANY_ID, param);
  }
  public downloadProjectCommunityList(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_PROJECT_COMMUNITY_LIST, param);
  }

  public downloadProjectHouseList(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_PROJECT_HOUSE_LIST, param);
  }

  public downloadProjectDeliverList(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_PROJECT_DELIVER_LIST, param);
  }

  public downloadHouseUse(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_HOUSE_USE, param);
  }

  public downloadHouseList(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_HOUSE_LIST, param);
  }

  public downloadSearchHouseList(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_SEARCH_HOUSE_LIST, param);
  }

  public downloadHouseListByContract(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_HOUSE_LIST_BY_CONTRACT, param);
  }

  public downloadTaskRecordList(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_TASK_RECORD_LIST, param);
  }

  public downloadTaskRecordListFu(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_TASK_RECORD_LIST_FU, param);
  }

  public downloadHouseDataByCommunity(param: any): Observable<any> {
    return this.http.post(this.api.DOWNLOAD_HOUSE_DATA_BY_COMMUNITY, param);
  }

  /**
   * 房源管理
   */
  public getRegionByCommunity(param: any): Observable<any> {
    return this.http.post(this.api.GET_REGION_BY_COMMUNITY(param));
  }

  public getBuildingByRegionAndCommunity(param: any): Observable<any> {
    return this.http.post(this.api.GET_BUILDING_BY_REGION_AND_COMMUNITY, param);
  }

  public houseList(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST, param);
  }

  public houseListByIds(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_BY_IDS, param);
  }

  public listWithOutHouse(param: any): Observable<any> {
    return this.http.post(this.api.LIST_WITH_OUT_HOUSE, param);
  }

  public houseListFast(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_FAST, param);
  }
  public houseListPatrol(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_PATROL, param);
  }
  public houseListByProduct(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_BY_PRODUCT, param);
  }

  public houseStatByProduct(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_STAT_BY_PRODUCT, param);
  }

  public viewHouse(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_HOUSE, param);
  }

  public houseTree(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_TREE(param));
  }

  public getUnitByCommunityAndBuilding(param: any): Observable<any> {
    return this.http.post(this.api.GET_UNIT_BY_COMMUNITY_AND_BUILDING, param);
  }

  public getFloorByComAndBuild(param: any): Observable<any> {
    return this.http.post(this.api.GET_FLOOR_BY_COM_AND_BUILD, param);
  }

  public editRemark(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_REMARK, param);
  }

  /**
   * 房源录入
   */
  public listEnter(param: any): Observable<any> {
    return this.http.post(this.api.LIST_ENTER, param);
  }

  public addEnter(param: any): Observable<any> {
    return this.http.post(this.api.ADD_ENTER, param);
  }

  public editEnter(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_ENTER, param);
  }

  public viewEnter(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_ENTER, param);
  }

  public checkEnter(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_ENTER, param);
  }

  public impEnter(param: any): Observable<any> {
    return this.http.post(this.api.IMP_ENTER, param);
  }

  public selectEnter(param: any): Observable<any> {
    return this.http.post(this.api.SELECT_ENTER, param);
  }

  public viewLog(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_LOG + '?id=' + param);
  }

  public viewLocalLog(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_LOCAL_LOG + '?id=' + param);
  }

  /**
   * 房源调配
   */
  public listAlloc(param: any): Observable<any> {
    return this.http.post(this.api.LIST_ALLOC, param);
  }

  public addAlloc(param: any): Observable<any> {
    return this.http.post(this.api.ADD_ALLOC, param);
  }

  public editAlloc(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_ALLOC, param);
  }

  public viewAlloc(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_ALLOC, param);
  }

  public checkAlloc(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_ALLOC, param);
  }

  public selectAlloc(param: any): Observable<any> {
    return this.http.post(this.api.SELECT_ALLOC, param);
  }

  public getHouseListAlloc(param: any): Observable<any> {
    return this.http.post(this.api.GET_HOUSE_LIST_ALLOC, param);
  }

  public getComputeAlloc(param: any): Observable<any> {
    return this.http.post(this.api.GET_COMPUTE_ALLOC, param);
  }

  public rejectAlloc(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_ALLOC, param);
  }

  public passAlloc(param: any): Observable<any> {
    return this.http.post(this.api.PASS_ALLOC, param);
  }

  /**
   * 房源调剂
   */
  public listOut(param: any): Observable<any> {
    return this.http.post(this.api.LIST_OUT, param);
  }

  public addOut(param: any): Observable<any> {
    return this.http.post(this.api.ADD_OUT, param);
  }

  public editOut(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_OUT, param);
  }

  public viewOut(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_OUT, param);
  }

  public checkOut(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_OUT, param);
  }

  public selectOut(param: any): Observable<any> {
    return this.http.post(this.api.SELECT_OUT, param);
  }

  public getComputeOut(param: any): Observable<any> {
    return this.http.post(this.api.GET_COMPUTE_OUT, param);
  }

  public addFileOut(param: any): Observable<any> {
    return this.http.post(this.api.ADD_FILE_OUT, param);
  }

  public rejectOut(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_OUT, param);
  }

  public passOut(param: any): Observable<any> {
    return this.http.post(this.api.PASS_OUT, param);
  }

  public listByOA(param: any): Observable<any> {
    return this.http.post(this.api.LIST_BY_OA, param);
  }

  /**
   * 房源签约过审核
   */
  public listSign(param: any): Observable<any> {
    return this.http.post(this.api.LIST_SIGN, param);
  }

  public addSign(param: any): Observable<any> {
    return this.http.post(this.api.ADD_SIGN, param);
  }

  public editSign(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_SIGN, param);
  }

  public viewSign(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_SIGN, param);
  }

  public checkSign(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_SIGN, param);
  }

  public rejectSign(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_SIGN, param);
  }

  public passSign(param: any): Observable<any> {
    return this.http.post(this.api.PASS_SIGN, param);
  }

  /**
   * 对接清册
   */
  public listDeliver(param: any): Observable<any> {
    return this.http.post(this.api.LIST_DELIVER, param);
  }

  public addDeliver(param: any): Observable<any> {
    return this.http.post(this.api.ADD_DELIVER, param);
  }

  public editDeliver(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_DELIVER, param);
  }

  public viewDeliver(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_DELIVER, param);
  }

  public checkDeliver(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_DELIVER, param);
  }

  public rejectDeliver(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_DELIVER, param);
  }

  public passDeliver(param: any): Observable<any> {
    return this.http.post(this.api.PASS_DELIVER, param);
  }

  public getLevyDeliver(param: any): Observable<any> {
    return this.http.post(this.api.LEVY_DELIVER, param);
  }

  /**
   * 房源退回
   */
  public listBack(param: any): Observable<any> {
    return this.http.post(this.api.LIST_BACK, param);
  }

  public addBack(param: any): Observable<any> {
    return this.http.post(this.api.ADD_BACK, param);
  }

  public editBack(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_BACK, param);
  }

  public viewBack(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_BACK, param);
  }

  public checkBack(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_BACK, param);
  }

  public getComputeBack(param: any): Observable<any> {
    return this.http.post(this.api.GET_COMPUTE_BACK, param);
  }

  public rejectBack(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_BACK, param);
  }

  public passBack(param: any): Observable<any> {
    return this.http.post(this.api.PASS_BACK, param);
  }
  public addFileBack(param: any): Observable<any> {
    return this.http.post(this.api.ADD_FILE_BACK, param);
  }
  public addRemarkBack(param: any): Observable<any> {
    return this.http.post(this.api.ADD_REMARK_BACK, param);
  }

  /**
   * 房源退订
   */
  public listReturn(param: any): Observable<any> {
    return this.http.post(this.api.LIST_RETURN, param);
  }

  public addReturn(param: any): Observable<any> {
    return this.http.post(this.api.ADD_RETURN, param);
  }

  public editReturn(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_RETURN, param);
  }

  public viewReturn(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_RETURN, param);
  }

  public checkReturn(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_RETURN, param);
  }

  public getComputeReturn(param: any): Observable<any> {
    return this.http.post(this.api.GET_COMPUTE_RETURN, param);
  }

  public rejectReturn(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_RETURN, param);
  }

  public passReturn(param: any): Observable<any> {
    return this.http.post(this.api.PASS_RETURN, param);
  }

  /**
   * 房源分配
   */
  public listDistribution(param: any): Observable<any> {
    return this.http.post(this.api.LIST_DISTRIBUTION, param);
  }

  public addDistribution(param: any): Observable<any> {
    return this.http.post(this.api.ADD_DISTRIBUTION, param);
  }

  public editDistribution(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_DISTRIBUTION, param);
  }

  public viewDistribution(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_DISTRIBUTION, param);
  }

  public checkDistribution(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_DISTRIBUTION, param);
  }

  /**
   * 历史数据录入
   */
  public listHistory(param: any): Observable<any> {
    return this.http.post(this.api.LIST_HISTORY, param);
  }

  public addHistory(param: any): Observable<any> {
    return this.http.post(this.api.ADD_HISTORY, param);
  }

  public editHistory(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_HISTORY, param);
  }

  public viewHistory(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_HISTORY, param);
  }

  public impHistory(param: any): Observable<any> {
    return this.http.post(this.api.IMP_HISTORY, param);
  }

  public delHistoryHouseId(param: any): Observable<any> {
    return this.http.post(this.api.DEL_HISTORY_HOUSEID, param);
  }

  public expHistoryError(param: any): Observable<any> {
    return this.http.post(this.api.EXP_HISTORY_ERROR, param);
  }

  public expHistoryErrorById(param: any): Observable<any> {
    return this.http.post(this.api.EXP_HISTORY_ERROR_BY_ID, param);
  }

  public rejectHistory(param: any): Observable<any> {
    return this.http.post(this.api.REJECT_HISTORY, param);
  }

  public completeHistory(param: any): Observable<any> {
    return this.http.post(this.api.COMPLETE_HISTORY, param);
  }

  public delHistory(param: any): Observable<any> {
    return this.http.post(this.api.DEL_HISTORY, param);
  }

  public expLanDataExcel(param: any): Observable<any> {
    return this.http.post(this.api.EXP_LAND_DATA, param);
  }

  public expBaseDataExcel(param: any): Observable<any> {
    return this.http.post(this.api.EXP_BASE_DATA, param);
  }

  public generateProjectHousingUsageInfoExcel(param: any): Observable<any> {
    return this.http.post(this.api.PROJECT_HOUSING_USAGE_DATA, param);
  }

  // /**
  //  * 房源交付
  //  */
  // public listDeliver(param: any): Observable<any> {
  //   return this.http.post(this.api.LIST_DELIVER, param);
  // }
  //
  // public getDeliverHouseInfoByProjectId(param: any): Observable<any> {
  //   return this.http.post(this.api.GET_DELIVER_HOUSE_INFO_BY_PROJECT_ID, param);
  // }
  //
  // public addDeliver(param: any): Observable<any> {
  //   return this.http.post(this.api.ADD_DELIVER, param);
  // }
  //
  // /**
  //  * 房源回退
  //  */
  // public listBack(param: any): Observable<any> {
  //   return this.http.post(this.api.LIST_BACK, param);
  // }
  //
  // public addBack(param: any): Observable<any> {
  //   return this.http.post(this.api.ADD_BACK, param);
  // }

  /**
   * 我的申请
   */
  public listApply(param: any): Observable<any> {
    return this.http.post(this.api.LIST_APPLY, param);
  }

  public myListApply(param: any): Observable<any> {
    return this.http.post(this.api.LIST_MY_APPLY, param);
  }

  public withdrawApply(param: any): Observable<any> {
    return this.http.post(this.api.WITHDRAW_APPLY(param));
  }

  public delApply(param: any): Observable<any> {
    return this.http.post(this.api.DEL_APPLY(param));
  }

  public listApplication(param: any): Observable<any> {
    return this.http.post(this.api.LIST_APPLICATION, param);
  }

  public getApplySubmit(param: any): Observable<any> {
    return this.http.post(this.api.GET_APPLY_SUBMIT + '?id=' + param);
  }

  /**
   * 草稿箱 --删除
   */
  public delDraft(param: any): Observable<any> {
    return this.http.post(this.api.DEL_DEAFT, param);
  }

  public applyWithdrawNew(param: any): Observable<any> {
    return this.http.post(this.api.WITHDRAW_NEW, param);
  }

  public delPropertyFeeDraft(param: any): Observable<any> {
    return this.http.post(this.api.DEL_PROPERTY_FEE_DEAFT, param);
  }

  public delPatrolFeeDraft(param: any): Observable<any> {
    return this.http.post(this.api.DEL_PATROL_FEE_DEAFT, param);
  }

  public delPayApplyDraft(param: any): Observable<any> {
    return this.http.post(this.api.DEL_PAY_APPLY_DEAFT, param);
  }

  public delFundReturnDraft(param: any): Observable<any> {
    return this.http.post(this.api.DEL_FUND_RETRUN_DEAFT, param);
  }

  /**
   * 工作台
   */

  public selectApplyAllList(param: any): Observable<any> {
    return this.http.post(this.api.APPLY_ALL_LIST, param);
  }

  public getApplyAllCount(): Observable<any> {
    return this.http.post(this.api.APPLY_ALL_COUNT);
  }

  public selectApplyMyList(param: any): Observable<any> {
    return this.http.post(this.api.APPLY_ALL_MY_LIST, param);
  }

  public getApplyMyCount(): Observable<any> {
    return this.http.post(this.api.APPLY_ALL_MY_COUNT);
  }

  /**
   * 开发商管理
   */
  public listDeveloper(param: any): Observable<any> {
    return this.http.post(this.api.LIST_DEVELOPER, param);
  }

  public addDeveloper(param: any): Observable<any> {
    return this.http.post(this.api.ADD_DEVELOPER, param);
  }

  public editDeveloper(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_DEVELOPER, param);
  }

  public viewDeveloper(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_DEVELOPER, param);
  }

  public delDeveloper(param: any): Observable<any> {
    return this.http.post(this.api.DEL_DEVELOPER, param);
  }

  /**
   * 征收户管理
   */
  public getLevyListByProject(param: any): Observable<any> {
    return this.http.post(this.api.GET_LEVY_LIST_BY_PROJECT, param);
  }

  public addLevy(param: any): Observable<any> {
    return this.http.post(this.api.ADD_LEVY, param);
  }

  public editLevy(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_LEVY, param);
  }

  public deleteLevy(param: any): Observable<any> {
    return this.http.post(this.api.DELETE_LEVY(param));
  }

  public batchImportLevy(param: any): Observable<any> {
    return this.http.post(this.api.BATCH_IMPORT_LEVY, param);
  }

  // 楼盘分区管理批量导入房源
  public addEnterBatch(param: any): Observable<any> {
    return this.http.post(this.api.ADD_ENTER_BATCH, param);
  }

  public impEnterBath(param: any): Observable<any> {
    return this.http.post(this.api.IMP_ENTER_BATCH, param);
  }

  public saveHouseExchange(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_HOUSE_EXCHANGE, param);
  }

  /**
   * 巡查
   */
  public getPatrolList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_PLAN_LIST, param);
  }

  /**
   * 巡查新增
   */
  public addPatro(param: any): Observable<any> {
    return this.http.post(this.api.ADD_PATROL_PLAN, param);
  }

  /**
   * 巡查编辑
   */
  public editPatro(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_PATROL_PLAN, param);
  }

  // 查看巡查计划
  public viewPatro(id: any): Observable<any> {
    return this.http.get(this.api.VIEW_PATROL_PLAN(id));
  }

  public taskPatrolStatusList(): Observable<any> {
    return this.http.get(this.api.TASK_PATROL_STATUS_LIST);
  }

  public taskStatusList(): Observable<any> {
    return this.http.get(this.api.TASK_STATUS_LIST);
  }

  public patrolTaskStatus(): Observable<any> {
    return this.http.get(this.api.PATROL_TASK_STATUS_LIST);
  }

  public recordStatusList(): Observable<any> {
    return this.http.get(this.api.RECORD_STATUS_LIST);
  }

  public patrolRecordList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_RECORD_LIST, param);
  }

  public patrolRecordDetail(id: any): Observable<any> {
    return this.http.post(this.api.PATROL_RECORD_DETAIL(id));
  }

  // 用户列表下拉选择
  public patrolUser(par: any): Observable<any> {
    return this.http.post(this.api.PATROL_USERS, par);
  }

  //
  public batchDispatch(par: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_BATCH_DISPATCH, par);
  }

  public dispatch(par: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_DISPATCH, par);
  }

  public listTask(param: any): Observable<any> {
    return this.http.post(this.api.LIST_TASK, param);
  }

  public addTask(param: any): Observable<any> {
    return this.http.post(this.api.ADD_TASK, param);
  }

  public patrolCount(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_COUNT, param);
  }

  /**
   * 房源库
   */
  public buildingHouseMatrix(param: any): Observable<any> {
    return this.http.post(this.api.BUILDING_HOUSE_MATRIX, param);
  }

  public HouseTreeByCommunity(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_TREE_BY_COMMUNITY, param);
  }

  public houseFlowRecord(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_FLOW_RECORD, param);
  }

  public deleteExchangeRecord(param: any): Observable<any> {
    return this.http.post(this.api.DELETE_EXCHANGE_RECORD, param);
  }

  public houseFundList(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_FUND_LIST, param);
  }

  public houseFundStatistics(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_FUND_STATISTICS, param);
  }

  public houseMoney(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_FLOW_MONEY, param);
  }

  public updateHouse(par: any): Observable<any> {
    return this.http.post(this.api.UPDATE_HOUSE, par);
  }

  public deleteHouse(par: any): Observable<any> {
    return this.http.post(this.api.DELETE_HOUSE, par);
  }

  public changeFreezeStatus(par: any): Observable<any> {
    return this.http.post(this.api.CHANGE_FREEZE_STATUS, par);
  }

  /**
   * 冻结和解冻
   */
  public getFreezeListOfFinish(par: any): Observable<any> {
    return this.http.post(this.api.FREEZE_LIST_OF_FINISH, par);
  }

  public getListOfInApproval(par: any): Observable<any> {
    return this.http.post(this.api.FREEZE_LIST_OF_IN_APPROVAL, par);
  }

  public getListOfClose(par: any): Observable<any> {
    return this.http.post(this.api.FREEZE_LIST_OF_CLOSE, par);
  }

  public addFreezeDraft(par: any): Observable<any> {
    return this.http.post(this.api.ADD_FREEZE_DRAFT, par);
  }

  public addFreezeApply(par: any): Observable<any> {
    return this.http.post(this.api.ADD_FREEZE_APPLY, par);
  }

  public addUnFreezeDraft(par: any): Observable<any> {
    return this.http.post(this.api.ADD_UNFREEZE_DRAFT, par);
  }

  public addUnfreezeApply(par: any): Observable<any> {
    return this.http.post(this.api.ADD_UNFREEZE_APPLY, par);
  }

  public getFreezeInfo(id: any): Observable<any> {
    return this.http.get(this.api.FREEZE_INFO(id));
  }

  public getUnfreezeInfo(id: any): Observable<any> {
    return this.http.get(this.api.UNFREEZE_INFO(id));
  }

  public projectManagerOfIndex(): Observable<any> {
    return this.http.get(this.api.PROJECT_MANAGER_OF_INDEX);
  }

  public communityCountOfIndex(): Observable<any> {
    return this.http.get(this.api.COMMUNITY_COUNT_OF_INDEX);
  }

  public communityManagerOfIndex(): Observable<any> {
    return this.http.get(this.api.COMMUNITY_MANAGER_OF_INDEX);
  }

  public communityManagerOfIndexTd(): Observable<any> {
    return this.http.get(this.api.COMMUNITY_MANAGER_OF_INDEX_TD);
  }

  public houseCountByIdentity(): Observable<any> {
    return this.http.get(this.api.HOUSE_COUNT_BY_IDENTITY);
  }

  public houseCountByIdentityTd(): Observable<any> {
    return this.http.get(this.api.HOUSE_COUNT_BY_IDENTITY_TD);
  }

  public progressProjectCount(): Observable<any> {
    return this.http.get(this.api.PROGRESS_PROJECT_COUNT);
  }

  public houseCountOfTpAndQhAndTj(): Observable<any> {
    return this.http.get(this.api.HOUSE_COUNT_OF_TP_AND_QH_AND_TJ);
  }

  public houseCountByCompanyId(id: any): Observable<any> {
    return this.http.get(this.api.HOUSE_COUNT_BY_COMPANY_ID(id));
  }

  public houseCountByCompanyIdTd(id: any): Observable<any> {
    return this.http.get(this.api.HOUSE_COUNT_BY_COMPANY_ID_TD(id));
  }

  public getHouseChangeStat(par: any): Observable<any> {
    return this.http.post(this.api.GET_HOUSE_CHANGE_STAT, par);
  }

  public getMyApply(par: any): Observable<any> {
    return this.http.post(this.api.GET_MY_APPLY, par);
  }

  public fundCount(par: any): Observable<any> {
    return this.http.post(this.api.FUND_COUNT, par);
  }

  public fundCountTD(par: any): Observable<any> {
    return this.http.post(this.api.FUND_COUNT_TD, par);
  }

  public fundCountOfGraph(par: any): Observable<any> {
    return this.http.post(this.api.FUND_COUNT_OF_GRAPH, par);
  }

  public fundCountOfGraphTD(par: any): Observable<any> {
    return this.http.post(this.api.FUND_COUNT_OF_GRAPH_TD, par);
  }

  /**
   * 资金
   */
  public payList(param: any): Observable<any> {
    return this.http.post(this.api.PAY_LIST, param);
  }

  public fundReturnList(param: any): Observable<any> {
    return this.http.post(this.api.FUND_RETURN_LIST, param);
  }

  public payAdd(param: any): Observable<any> {
    return this.http.post(this.api.PAY_ADD, param);
  }

  public payEdit(param: any): Observable<any> {
    return this.http.post(this.api.PAY_EDIT, param);
  }

  public payHistoryAdd(param: any): Observable<any> {
    return this.http.post(this.api.PAY_HISTORY_ADD, param);
  }

  public payHistoryEdit(param: any): Observable<any> {
    return this.http.post(this.api.PAY_HISTORY_EDIT, param);
  }

  public payHistoryCheck(param: any): Observable<any> {
    return this.http.post(this.api.PAY_HISTORY_CHECK, param);
  }

  public returnAdd(param: any): Observable<any> {
    return this.http.post(this.api.RETURN_ADD, param);
  }

  public viewPayApply(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_PAY_APPLY, param);
  }

  public checkPayApply(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_PAY_APPLY, param);
  }

  public checkReturnApply(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_RETURN_APPLY, param);
  }

  public checkReturnApplyHistory(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_RETURN_APPLY_HISTORY, param);
  }

  public viewReturnApply(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_RETURN_APPLY, param);
  }

  public getApplyInfoHouses(param: any): Observable<any> {
    return this.http.post(this.api.APPLY_INFO_HOUSES, param);
  }

  public getFundReturnApplyInfoHouses(param: any): Observable<any> {
    return this.http.post(this.api.FUND_RETURN_APPLY_INFO_HOUSES, param);
  }

  public fundTransition(param: any): Observable<any> {
    return this.http.post(this.api.FUND_TRANSITION, param);
  }

  public fundReturnPassed(param: any): Observable<any> {
    return this.http.post(this.api.FUND_RETURN_PASSED, param);
  }

  public returnEdit(param: any): Observable<any> {
    return this.http.post(this.api.FUND_RETURN_EDIT, param);
  }

  public propertyFeeList(param: any): Observable<any> {
    return this.http.post(this.api.PROPERTY_FEE_LIST, param);
  }

  public propertyFeeApplyEdit(param: any): Observable<any> {
    return this.http.post(this.api.PROPERTY_FEE_APPLY_EDIT, param);
  }

  public computePropertyFeePay(param: any): Observable<any> {
    return this.http.post(this.api.COMPUTE_PROPERTY_FEE_PAY, param);
  }

  public propertyFeeApplyAdd(param: any): Observable<any> {
    return this.http.post(this.api.PROPERTY_FEE_APPLY_ADD, param);
  }

  public viewPropertyFeeApply(param: any): Observable<any> {
    return this.http.post(this.api.PROPERTY_FEE_APPLY_VIEW, param);
  }

  public checkPropertyApply(param: any): Observable<any> {
    return this.http.post(this.api.CHECK_PROPERTY_APPLY, param);
  }

  public patrolFeeList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_FEE_LIST, param);
  }

  public patrolFeeApplyEdit(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_FEE_APPLY_EDIT, param);
  }

  public patrolFeeApplyAdd(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_FEE_APPLY_ADD, param);
  }

  public patrolFeeApplyView(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_FEE_APPLY_VIEW, param);
  }

  public housePayStat(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_PAY_STAT, param);
  }

  public moneyReturnStat(param: any): Observable<any> {
    return this.http.post(this.api.MONEY_RETURN_STAT, param);
  }

  public propertyFeeStat(param: any): Observable<any> {
    return this.http.post(this.api.PROPERTY_FEE_STAT, param);
  }

  public patrolFeeStat(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_FEE_STAT, param);
  }

  public getFundFinancialCostList(param: any): Observable<any> {
    return this.http.post(this.api.FUND_FINANCIAL_COST_LIST, param);
  }

  public getHouseExchangeByCommunityList(param: any): Observable<any> {
    return this.http.post(this.api.FUND_House_Exchange_Community_LIST, param);
  }

  public exportHouseDetail(param: any): Observable<any> {
    return this.http.post(this.api.EXPORT_HOUSE_DETAIL, param);
  }

  public exportHouseStat(param: any): Observable<any> {
    return this.http.post(this.api.EXPORT_HOUSE_STAT, param);
  }

  /**
   * 合同
   */
  public contractList(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_LIST, param);
  }

  public contractNodeList(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_NODE_LIST, param);
  }

  public getCertInfoList(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_CERT_LIST, param);
  }

  public houseListByContract(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_BY_CONTRACT, param);
  }

  public listContract(param: any): Observable<any> {
    return this.http.post(this.api.LIST_CONTRACT, param);
  }

  public contractFundStat(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_FUND_STAT, param);
  }

  public contractHouseStat(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_HOUSE_STAT, param);
  }

  public viewContract(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_CONTRACT, param);
  }

  public contractFundRecord(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_FUND_RECORD, param);
  }

  public contractHouseList(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_HOUSE_LIST, param);
  }

  public getRelatedContract(param: any): Observable<any> {
    return this.http.post(this.api.RELATED_CONTRACT_LIST, param);
  }

  public contractEdit(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACT_EDIT, param);
  }

  /**
   * 房源备案
   */
  public houseRecord(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_RECORD, param);
  }

  public getRecordInfo(houseId: any): Observable<any> {
    return this.http.get(this.api.GET_RECORD_INFO(houseId));
  }

  public editRecord(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_RECORD, param);
  }

  public recordHouseList(param: any): Observable<any> {
    return this.http.post(this.api.RECORD_HOUSE_LIST, param);
  }

  public deleteRecord(houseId: any): Observable<any> {
    return this.http.delete(this.api.DELETE_RECORD(houseId));
  }

  /**
   * 分配使用部门
   */
  public listByCompanyIdAndTag(param: any): Observable<any> {
    return this.http.get(this.api.LIST_BY_COMPANY_ID_AND_TAG, param);
  }

  public toUseDeptSingle(param: any): Observable<any> {
    return this.http.post(this.api.TO_USE_DEPT_SINGLE, param);
  }

  public toUseDeptBatch(param: any): Observable<any> {
    return this.http.post(this.api.TO_USE_DEPT_BATCH, param);
  }

  public toUseDeptList(param: any): Observable<any> {
    return this.http.post(this.api.TO_USE_DEPT_LIST, param);
  }

  public toUseDeptBack(param: any): Observable<any> {
    return this.http.post(this.api.TO_USE_DEPT_BACK, param);
  }

  /**
   * 可视化接口
   */
  public visualHouseStat(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_HOUSE_STAT, param);
  }

  public visualHouseExchangeStat(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_HOUSE_EXCHANGE_STAT, param);
  }

  public visualProjectStat(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_PROJECT_STAT, param);
  }

  public visualHouseListCount(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_HOUSE_LIST_COUNT, param);
  }

  public visualProjectList(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_PROJECT_LIST, param);
  }

  public visualCommunityList(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_COMMUNITY_LIST, param);
  }

  public visualHouseListPop(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_HOUSE_LIST_POP, param);
  }

  public visualFundStat(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_FUND_STAT, param);
  }

  public visualCommunityHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_COMMUNITY_HOUSE_COUNT, param);
  }

  public visualProjectHouseCount(param: any): Observable<any> {
    return this.http.post(this.api.VISUAL_PROJECT_HOUSE_COUNT, param);
  }

  public exceptionHouseStat(param: any): Observable<any> {
    return this.http.post(this.api.EXCEPTION_HOUSE_STAT, param);
  }

  public exceptionHouseList(param: any): Observable<any> {
    return this.http.post(this.api.EXCEPTION_HOUSE_LIST, param);
  }

  /**
   * 巡查相关接口
   */
  public getPatrolLeaderList(): Observable<any> {
    return this.http.get(this.api.PATROL_LEADER_LIST);
  }

  public patrolAddUser(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_ADD_USER, param);
  }

  public getPatrolUserList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_USER_LIST, param);
  }

  public editPatrolUser(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_USER_EDIT, param);
  }

  public resetPatrolUserPassword(param: any): Observable<any> {
    return this.http.get(this.api.PATROL_USER_RESET_PASSWORD(param));
  }

  public unBindPatrolUserWX(param: any): Observable<any> {
    return this.http.get(this.api.PATROL_USER_UNBIND_WX(param));
  }

  public disabledPatrolUser(param: any): Observable<any> {
    return this.http.get(this.api.PATROL_USER_DISABLED(param));
  }

  public enabledPatrolUser(param: any): Observable<any> {
    return this.http.get(this.api.PATROL_USER_ENABLED(param));
  }

  public getCanPatrolHouseByCommunity(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_CAN_HOUSE_COMMUNITY, param);
  }

  public addPatrolHouseByCommunity(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_ADD_HOUSE_COMMUNITY, param);
  }

  public getPatrolCommunityHouseStat(): Observable<any> {
    return this.http.post(this.api.PATROL_COMMUNITY_HOUSE_STAT_COUNT);
  }

  public getPatrolCommunityHouseList(): Observable<any> {
    return this.http.post(this.api.PATROL_COMMUNITY_HOUSE_LIST, {});
  }

  public addPatrolTask(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_ADD_TASK, param);
  }

  public addPatrolBatchTask(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_ADD_BATCH_TASK, param);
  }

  public getPatrolTaskListByManager(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_LIST_MANAGER, param);
  }

  public getPatrolTaskListByLeader(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_LIST_LEADER, param);
  }

  public getPatrolDetail(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_DETAIL, param);
  }

  public getPatrolTaskHouseList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_HOUSE_LIST, param);
  }

  public getPatrolCheckListByManager(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_CHECK_LIST, param);
  }

  public getPatrolTaskRecordDetail(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_RECORD_DETAIL, param);
  }

  public getPatrolCommunityDetail(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_COMMUNITY_DETAIL, param);
  }

  public getPatrolCommunityCommHouseList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_COMMUNITY_COMM_HOUSE_LIST, param);
  }

  public getPatrolCommunityRecordList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_COMMUNITY_RECORD_LIST, param);
  }

  public getPatrolCommunityCheckList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_COMMUNITY_CHECK_LIST, param);
  }

  public getPatrolCheckDetail(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_CHECK_DETAIL, param);
  }

  public getPatrolCheckHouseList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_CHECK_HOUSE_LIST, param);
  }

  public getPatrolCheckHouseDetail(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_CHECK_HOUSE_DETAIL, param);
  }

  public getPatrolHouseRecordList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_HOUSE_RECORD_LIST, param);
  }

  public patrolTaskBatchTo(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_BATCH_TO, param);
  }

  public patrolTaskTo(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_TO, param);
  }

  public patrolTaskRecordConfirm(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_RECORD_CONFIRM, param);
  }

  public patrolBatchConfirm(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_BATCH_CONFIRM, param);
  }

  public patrolUpdateRecordInfo(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_RECORD_UPDATE_INFO, param);
  }

  public patrolTaskVerifySubmit(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_VERIFY_SUBMIT, param);
  }

  public patrolTaskUnVerifyRecordCount(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_TASK_UN_VERIFY_RECORD_COUNT, param);
  }

  public getContractNoList(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACTNO_LIST, param);
  }

  public getContractInfo(param: any): Observable<any> {
    return this.http.post(this.api.CONTRACTNO_LIST_INFO, param);
  }

  public patrolRecordListByHouse(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_RECORD_LIST_BY_HOUSE, param);
  }

  public createPatrolReport(param: any): Observable<any> {
    return this.http.post(this.api.CREATE_PATROL_REPORT, param);
  }

  public editPatrolReport(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_PATROL_REPORT, param);
  }

  public exportPatrolRptInfo(param: any): Observable<any> {
    return this.http.post(this.api.EXPORT_PATROL_REPORT, param);
  }

  public getPatrolRptInfo(param: any): Observable<any> {
    return this.http.post(this.api.GET_PATROL_RPT_INFO, param);
  }

  public getPatrolRptList(param: any): Observable<any> {
    return this.http.post(this.api.GET_PATROL_RPT_LIST, param);
  }

  public deletePatrolTask(param: any): Observable<any> {
    return this.http.post(this.api.DELETE_PATROL_TASK, param);
  }

  public createSpotCheckReport(param: any): Observable<any> {
    return this.http.post(this.api.CREATE_SPOT_CHECK_REPORT, param);
  }

  public deleteSpotCheckRpt(param: any): Observable<any> {
    return this.http.post(this.api.DELETE_SPOT_CHECK_RPT, param);
  }

  public editSpotCheckReport(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_SPOT_CHECK_REPORT, param);
  }

  public exportSpotCheckRptInfo(param: any): Observable<any> {
    return this.http.post(this.api.EXPORT_SPOT_CHECK_RPT_INFO, param);
  }

  public exportSpotCheckRptInfoDetail(id: any): Observable<any> {
    return this.http.get(this.api.EXPORT_SPOT_CHECK_RPT_INFO_DETAIL(id));
  }

  public getSpotCheckRptInfo(param: any): Observable<any> {
    return this.http.post(this.api.GET_SPOT_CHECK_RPT_INFO, param);
  }

  public getSpotCheckRptList(param: any): Observable<any> {
    return this.http.post(this.api.GET_SPOT_CHECK_RPT_LIST, param);
  }

  public getCommunitysBySpotCheck(): Observable<any> {
    return this.http.get(this.api.GET_COMMUNITY_BY_SPOT_CHECK);
  }

  public getHouseListByList(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_BY_LIST, param);
  }

  public getHouseListInfoByList(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_INFO_BY_LIST, param);
  }

  public getHouseListByMoney(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_LIST_BY_MONEY, param);
  }

  public selectHouse(param: any): Observable<any> {
    return this.http.post(this.api.SELECT_HOUSE, param);
  }

  public listLog(param: any): Observable<any> {
    return this.http.post(this.api.LIST_LOG, param);
  }

  public addDump(param: any): Observable<any> {
    return this.http.post(this.api.ADD_DUMP, param);
  }

  public viewDump(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_DUMP, param);
  }

  public addLock(param: any): Observable<any> {
    return this.http.post(this.api.ADD_LOCK, param);
  }

  public addBatchHouseStatus(param: any): Observable<any> {
    return this.http.post(this.api.ADD_BATCH_HOUSE_STATUS, param);
  }
  public addBatchHouseInfo(param: any): Observable<any> {
    return this.http.post(this.api.ADD_BATCH_HOUSE_INFO, param);
  }

  public queryLock(param: any): Observable<any> {
    return this.http.post(this.api.QUERY_LOCK, param);
  }

  public getPatorlDistrictLogList(param: any): Observable<any> {
    return this.http.post(this.api.PATROL_DISTRICT_LOG_LIST, param);
  }

  public viewPatorlDistrictLog(param: any): Observable<any> {
    return this.http.post(this.api.VIEW_PATROL_DISTRICT_LOG, param);
  }

  public houseRemarkLogListByIds(param: any): Observable<any> {
    return this.http.post(this.api.HOUSE_REMARK_LOG_LIST_BY_ID, param);
  }

  public exportRptHouseList(param: any): Observable<any> {
    return this.http.post(this.api.EXPORT_SPOT_CHECK_RPT_HOUSE_LIST, param);
  }
}
