<div class="page-tag-frame">
  <nz-tabset
    style="position: absolute; top: 70px"
    nzType="editable-card"
    nzSize="small"
    [(nzSelectedIndex)]="index"
    [nzHideAdd]="true"
    (nzSelectedIndexChange)="onChangeDump($event)"
    (nzClose)="onClose($event)"
  >
    <nz-tab *ngFor="let tab of tagInfo; let i = index" [nzClosable]="tab.isClose" [nzTitle]="tab.title" (click)="onChangeDump(i)"></nz-tab>
  </nz-tabset>
</div>
