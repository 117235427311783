import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NbXlsxService } from '@service/nb-xlsx.service';
import { NbApiService } from '@service/nb-api.service';
import { DatePipe } from '@angular/common';
import { API } from '@service/api';
import { ShowyePageAuthService } from '@showye/showye-page-base';
import { FormBuilder } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AppReuseStrategy } from '../customReuseStrategy';
import { RouterCacheService } from '@service/router-cache.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-page-tag',
  templateUrl: './page-tag.component.html',
  styleUrls: ['./layout.scss'],
})
export class PageTagComponent implements OnInit, OnChanges {
  tagInfo: any[] = [];
  index = -1;

  constructor(
    private xlsx: NbXlsxService,
    private api: NbApiService,
    private datePipe: DatePipe,
    modalSrv: NzModalService,
    private Api: API,
    private auth: ShowyePageAuthService,
    private fb: FormBuilder,
    public notify: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private routerCacheService: RouterCacheService,
  ) {
    modalSrv.closeAll();
  }

  ngOnInit(): void {
    this.tagInfo = [{ title: '工作台', path: '/home/work/workbench', isSelected: true }];
    this.routerCacheService.outputStream.subscribe((param: any) => {
      // this.route.data.subscribe(res => console.log(res));
      // console.log(this.tagInfo, param, this.index);
      if (param.type === 1 && param.data?.length > 0) {
        param.data.forEach((x: any, i: number) => {
          x.title === '工作台' ? (x.isClose = false) : (x.isClose = true);
          if (x.isSelected) {
            this.index = i;
          }
        });
        this.tagInfo = param.data;
      }
      // else if (param.type === 2) {
      //   this.tagInfo = this.tagInfo.map((x: any, i: number) => {
      //     x.isSelected = x.path === param.url;
      //     x.isSelected ? this.index = i : this.index = this.tagInfo.length - 1;
      //     return x;
      //   });
      //   console.log(this.tagInfo, param);
      // } else {
      // }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onClose(ev: any): void {
    AppReuseStrategy.deleteRouteSnapshot(this.tagInfo[ev.index].path);
    this.tagInfo.splice(ev.index, 1);
  }

  onChangeDump(ev: any): void {
    this.index = ev;
    if (ev > -1) {
      this.router.navigate([this.tagInfo[ev].path], { relativeTo: this.route });
    }
  }
}
